import React, { useEffect, useRef, useState } from 'react'
import './EditMultilinkSection.css'
import { EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT, EDIT_SHORT_PROMOCODE_INPUT_MAX_LENGTH, EDIT_SHORT_SECTION_URL_TEST_DELAY_MILLISECONDS, INPUT_VALIDATION_INDICATIONS, MULTILINK_SUBLINK_SELECTION_TYPE, VIEW_MODES, MULTILINK_SUBLINK_TYPES, MULTILINK_SUBLINK_DEVICE_OPTIONS_VALUE_ELEMENT } from 'constants/general.constants'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { useTranslation } from 'react-i18next'
import UTMForm from 'components/UTMForm/UTMForm'
import { isMobile } from 'react-device-detect'
import { useUserDetailsContext } from 'contexts/User'
import { URL_REGEX } from 'constants/regex.constants'
import { IoShirtOutline } from 'react-icons/io5'
import UrlSelectionType from 'components/UrlSelectionType/UrlSelectionType'
import { HiOutlineLink } from 'react-icons/hi'
import ShortUrlSelectionCollectionTypeImage from 'embeddedImages/ShortUrlSelectionCollectionTypeImage'
import AddProductsDialog from 'dialogs/AddProductsDialog/AddProductsDialog'
import AddCollectionsDialog from 'dialogs/AddCollectionsDialog/AddCollectionsDialog'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import { PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { AiOutlinePercentage } from "react-icons/ai";
import PaydinSelect from 'components/PaydinSelect/PaydinSelect'
import InstantStoresImage from 'embeddedImages/InstantStoresImage'
import MultiSelectStatusButton from 'components/MultiSelectStatusButton/MultiSelectStatusButton'

/**
 * Represents the section for editing/creating a multilink.
 * @param {string} mode - The mode of this section, it determins whether it is creating or editing a short, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT
 * @param {array} influencers - The list of influencers to allow influencer select
 * @param {string} title - The title of this short
 * @param {function} setTitle - A function for setting the title of this short
 * @param {string} url - The currently displayed url
 * @param {function} setURL - A function for setting the currently displayed url
 * @param {string} originalInfluencerUsername - The currently saved influencer's username
 * @param {string} selectedInfluencerUsername - The currently selected influencer's username
 * @param {function} setSelectedInfluencerUsername - A function for setting the currently seelcted influencer's username
 * @param {string} promocode - The promocode state
 * @param {function} setPromocode - A function for setting the promocode state
 * @param {boolean} shouldAddUTMs - Determins whether the {@link UTMForm} component is visible or not, also determins whether to ignore the UTM fields or not
 * @param {function} setShouldAddUTMs - A function for setting the 'shouldAddUTMs' state
 * @param {string} UTMSource - The UTM source value state
 * @param {function} setUTMSource - A function for setting the UTM source value state
 * @param {string} UTMMedium - The UTM medium value state
 * @param {function} setUTMMedium - A function for setting the UTM medium value state
 * @param {string} UTMCampaign - The UTM campaign value state
 * @param {function} setUTMCampaign - A function for setting the UTM campaign value state
 * @param {string} UTMTerm - The UTM term value state
 * @param {function} setUTMTerm - A function for setting the UTM term value state
 * @param {string} UTMContent - The UTM content value state
 * @param {function} setUTMContent - A function for setting the UTM content value state
 * @param {string} urlsInputValidationState - The validation status of the urls
 * @param {function} setUrlInputValidationState - A function for setting the validation status of the url
 * @param {boolean} hasSavedInfluencer - Determins whether an influencer was already saved or not
 * @param {function} setIsInfluencerChanged - A function to perform whenever an influencer has changed
 * @param {string} urlSelectionType - The type of url selection for this short
 * @param {function} setUrlSelectionType - A function for setting the type of url selection for this short
 * @param {array} selectedProducts - The list of selected products for this short
 * @param {function} setSelectedProducts - A function for setting the list of selected products for this short
 * @param {array} selectedCollections - The list of selected collections for this short
 * @param {function} setSelectedCollections - A function for setting the list of selected collections for this short
 */
export default function EditMultilinkSection({
    mode,
    title = '',
    setTitle = () => { },
    urls = [],
    setURL = (urlIndex) => { },
    promocode = '',
    setPromocode = () => { },
    shouldAddUTMs = false,
    setShouldAddUTMs = () => { },
    utmParams = {},
    setUTMSource = () => { },
    setUTMMedium = () => { },
    setUTMCampaign = () => { },
    setUTMTerm = () => { },
    setUTMContent = () => { },
    urlsInputValidationState = [{}, {}],
    setUrlInputValidationState = (urlIndex) => { },
    urlSelectionTypes = [MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM, MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM],
    setUrlSelectionType = (urlIndex) => { },
    selectedProducts = [[], []],
    setSelectedProducts = (linkIndex) => { },
    selectedCollections = [[], []],
    setSelectedCollections = (linkIndex) => { },
    multilinkPropertiesModified = false,
    splittingValues = [100, 0],
    setSplittingValue = (linkIndex) => { },
    deviceOptions = ['ALL', 'ALL'],
    setDeviceOptions = (linkIndex) => { }
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        endEditing
    } = useUserDetailsContext()
    const history = useHistoryContext()

    const [multiSelectDialogsState, setMultiSelectDialogsState] = useState({
        productsDialogShown: false,
        collectionsDialogShown: false,
        index: 0,
        urlSelectionType: '',
        maxLimit: 1,
        tempSelectedProducts: [],
    })
    const [urlSelectionOptionDimension, setUrlSelectionOptionDimension] = useState([0, 0])
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })

    const [isDeviceSelectOpen, setIsDeviceSelectOpen] = useState([false, false])

    const urlTypingTimeoutRefs = [useRef(null), useRef(null)]
    const urlSelectionOptionsRefs = [useRef(null), useRef(null)]

    function removeTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    useEffect(() => {
        if (urlSelectionOptionsRefs[0]?.current || urlSelectionOptionsRefs[1]?.current) {
            setUrlSelectionOptionDimension(prev => [
                urlSelectionOptionsRefs[0]?.current ? `min(calc((${urlSelectionOptionsRefs[0]?.current?.clientWidth}px - 55px) / 4), 120px)` : prev[0],
                urlSelectionOptionsRefs[1]?.current ? `min(calc((${urlSelectionOptionsRefs[1]?.current?.clientWidth}px - 55px) / 4), 120px)` : prev[1],
            ])
        }
    }, [])

    useEffect(() => {
        urls.map((url, index) => {
            if (isCustomUrlTypeSelected(index)) {
                removeTimeout(urlTypingTimeoutRefs[index]?.current)
                if (!url) {
                    setUrlInputValidationState(index, {
                        status: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
                        isValidating: false
                    })
                } else {
                    setUrlInputValidationState(prev => ({
                        status: INPUT_VALIDATION_INDICATIONS.NO_INDICATION,
                        isValidating: true
                    }))
                    urlTypingTimeoutRefs[index].current = setTimeout(() => {
                        const urlToTest = url.startsWith('https://') || url.startsWith('http://') ? url : `https://${url}`
                        const hasBusinessDomain = urlToTest.startsWith('https://' + userDetails?.domain) || urlToTest.startsWith('http://' + userDetails?.domain)
                        if (hasBusinessDomain && urlToTest.match(URL_REGEX) != null) {
                            setUrlInputValidationState(index, {
                                status: INPUT_VALIDATION_INDICATIONS.VALID,
                                isValidating: false
                            })
                        } else {
                            setUrlInputValidationState(index, {
                                status: INPUT_VALIDATION_INDICATIONS.INVALID,
                                isValidating: false
                            })
                        }
                    }, EDIT_SHORT_SECTION_URL_TEST_DELAY_MILLISECONDS)
                }
            }
        })
    }, [urls])

    function updateSplittingValue(index, e) {
        const intValue = parseInt(e.target.value)
        if (intValue <= 100 && intValue >= 0) {
            setSplittingValue(index, intValue)
        } else {
            setSplittingValue(index, Math.abs(100 - intValue) < intValue ? 100 : 0)
        }
    }

    function openAddProductsDialog(index, urlSelectionType) {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            productsDialogShown: true,
            index,
            urlSelectionType,
            maxLimit: urlSelectionType === MULTILINK_SUBLINK_SELECTION_TYPE.LOOK ? 15 : 1,
            tempSelectedProducts: urlSelectionTypes[index] !== urlSelectionType ? [] : selectedProducts[index],
        }))
    }

    function closeAddProductDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            productsDialogShown: false
        }))
    }

    function openAddCollectionsDialog(index) {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: true,
            index,
            maxLimit: 1,
        }))
    }

    function closeAddCollectionsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: false
        }))
    }

    function selectCustomUrl(index) {
        setSelectedProducts(index, [])
        setSelectedCollections(index, [])
        setURL(index, '')
        setUrlSelectionType(index, MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM)
    }

    function setUserSelectedProducts(index, selectedProducts, urlSelectionType) {
        if (selectedProducts?.length > 0) {
            setSelectedCollections(index, [])
            setUrlSelectionType(index, urlSelectionType)
            setURL(index, selectedProducts[0]?.url)
        }
        setSelectedProducts(index, selectedProducts)
    }

    function setUserSelectedCollections(index, selectedCollections) {
        if (selectedCollections?.length > 0) {
            setSelectedProducts(index, [])
            setUrlSelectionType(index, MULTILINK_SUBLINK_SELECTION_TYPE.COLLECTION)
            setURL(index, selectedCollections[0]?.url)
        }
        setSelectedCollections(index, selectedCollections)
    }

    function isProductUrlTypeSelected(index) {
        return urlSelectionTypes[index] === MULTILINK_SUBLINK_SELECTION_TYPE.PRODUCT
    }

    function isCollectionUrlTypeSelected(index) {
        return urlSelectionTypes[index] === MULTILINK_SUBLINK_SELECTION_TYPE.COLLECTION
    }

    function isCustomUrlTypeSelected(index) {
        return urlSelectionTypes[index] === MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM
    }

    function showDiscardChangesDialog() {
        setDialogState({
            isOpen: true,
            handleDialogClose: closeDialog,
            title: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_TITLE'),
            message: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_MESSAGE'),
            leftButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: () => {
                if (multilinkPropertiesModified) {
                    history.goBackStepsNoPerform(3)
                    endEditing()
                }
                setTimeout(goToPricingPlans, 200)
            }
        })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function goToPricingPlans() {
        history.push(PRICING_PLANS_PAGE_URL)
    }
    

    function allowLinkTypeSelection(index, linkType) {
        if (mode !== VIEW_MODES.CREATE) {
            if (isProductUrlTypeSelected(index) || isCollectionUrlTypeSelected(index) || isCustomUrlTypeSelected(index)) {
                return linkType === MULTILINK_SUBLINK_TYPES.SHORT ? true : false
            }
            return linkType === MULTILINK_SUBLINK_TYPES.LOOK ? true : false
        }
        return true
    }

    return (
        <div className={isMobile ? 'mobile-edit-multilink-section-container' : 'edit-multilink-section-container'}>
            <div className="edit-multilink-section-header-container">
                <div className="edit-multilink-section-header">{t("EDIT_MULTILINK_SECTION_HEADER")}</div>
                <div className="edit-multilink-section-subheader">{t("EDIT_MULTILINK_SECTION_SUBHEADER")}</div>
            </div>
            <div className={isMobile ? "mobile-edit-multilink-section-title-container" : "edit-multilink-section-title-container"}>
                <EditFieldSectionInput
                    value={title}
                    setValue={setTitle}
                    isFramed={true}
                    maxLength={EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT}
                    title={t('EDIT_MULTILINK_TITLE_INPUT_TITLE')}
                    isOptional={false}
                />
            </div>
            <div className='edit-multilink-sublink-sections-container'>
                {urls.length > 0 && <div className='edit-multilink-sublink-section'>
                    <div className="edit-multilink-section-experience-header-container">
                        <div className="edit-multilink-section-experience-header">{t("EDIT_MULTILINK_SECTION_EXPERIENCE_HEADER", { index: "A" })}</div>
                        <div className={isMobile ? "mobile-edit-multilink-section-experience-options" : "edit-multilink-section-experience-options"}>
                            <div className="edit-multilink-section-device-select" onClick={() => setIsDeviceSelectOpen(prev => [!prev[0], prev[1]])}>
                                <div className="edit-multilink-section-experience-device-option-header">{t("EDIT_MULTILINK_SECTION_DEVICE_HEADER")}</div>
                                <div className="edit-multilink-section-experience-device-option-box">
                                    <PaydinSelect
                                        isOpen={isDeviceSelectOpen[0]}
                                        options={MULTILINK_SUBLINK_DEVICE_OPTIONS_VALUE_ELEMENT}
                                        optionState={deviceOptions[0]}
                                        onOptionClick={(option) => setDeviceOptions(0, option)}
                                        hideArrow={false}
                                        textColor='hsl(229, 23%, 72%)'
                                        width='200px'
                                    />
                                </div>
                            </div>
                            <div className="edit-multilink-section-experience-search-container">
                                <div className="edit-multilink-section-experience-search-box-header">{t("EDIT_MULTILINK_SECTION_TRAFFIC_HEADER")}</div>
                                <div className="edit-multilink-section-search-box">
                                    <input className='edit-multilink-section-search-box-input' value={splittingValues[0]} pattern="[0-9]*" onChange={(value) => updateSplittingValue(0, value)} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                    <AiOutlinePercentage className='edit-multilink-section-search-box-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={urlSelectionOptionsRefs[0]} className="edit-multilink-section-url-selection-types">
                        <UrlSelectionType
                            title={t(isMobile ? 'EDIT_MULTILINK_SECTION_URL_SELECTION_LOOK_TYPE_TITLE_MOBILE' : 'EDIT_MULTILINK_SECTION_URL_SELECTION_LOOK_TYPE_TITLE')}
                            image={InstantStoresImage}
                            isSelected={urlSelectionTypes[0] === MULTILINK_SUBLINK_SELECTION_TYPE.LOOK}
                            imageClassName='edit-multilink-section-url-selection-type-image edit-multilink-section-url-selection-svg-type-image'
                            onClick={() => openAddProductsDialog(0, MULTILINK_SUBLINK_SELECTION_TYPE.LOOK)}
                            dimens={urlSelectionOptionDimension[0]}
                            isDisabled={!allowLinkTypeSelection(0, MULTILINK_SUBLINK_TYPES.LOOK)}
                        />
                        <UrlSelectionType
                            title={isProductUrlTypeSelected(0) ? selectedProducts[0][0]?.title : t('EDIT_MULTILINK_SECTION_URL_SELECTION_PRODUCT_TYPE_TITLE')}
                            image={isProductUrlTypeSelected(0) ? selectedProducts[0][0]?.imageUrl : IoShirtOutline}
                            isSelected={isProductUrlTypeSelected(0)}
                            imageClassName='edit-multilink-section-url-selection-type-image'
                            onClick={() => openAddProductsDialog(0, MULTILINK_SUBLINK_SELECTION_TYPE.PRODUCT)}
                            dimens={urlSelectionOptionDimension[0]}
                            hasChangedData={isProductUrlTypeSelected(0)}
                            isDisabled={!allowLinkTypeSelection(0, MULTILINK_SUBLINK_TYPES.SHORT)}
                        />
                        <UrlSelectionType
                            title={isCollectionUrlTypeSelected(0) ? selectedCollections[0][0]?.title : t('EDIT_MULTILINK_SECTION_URL_SELECTION_COLLECTION_TYPE_TITLE')}
                            image={isCollectionUrlTypeSelected(0) ? selectedCollections[0][0]?.images?.medium?.src : ShortUrlSelectionCollectionTypeImage}
                            isSelected={isCollectionUrlTypeSelected(0)}
                            imageClassName='edit-multilink-section-url-selection-type-image edit-multilink-section-url-selection-svg-type-image'
                            onClick={() => openAddCollectionsDialog(0)}
                            dimens={urlSelectionOptionDimension[0]}
                            hasChangedData={isCollectionUrlTypeSelected(0)}
                            isDisabled={!allowLinkTypeSelection(0, MULTILINK_SUBLINK_TYPES.SHORT)}
                        />
                        <UrlSelectionType
                            title={t(isMobile ? 'EDIT_MULTILINK_SECTION_URL_SELECTION_CUSTOM_TYPE_TITLE_MOBILE' : 'EDIT_MULTILINK_SECTION_URL_SELECTION_CUSTOM_TYPE_TITLE')}
                            image={HiOutlineLink}
                            isSelected={urlSelectionTypes[0] === MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM}
                            imageClassName='edit-multilink-section-url-selection-type-image'
                            onClick={() => selectCustomUrl(0)}
                            dimens={urlSelectionOptionDimension[0]}
                            isDisabled={!allowLinkTypeSelection(0, MULTILINK_SUBLINK_TYPES.SHORT)}
                        />
                    </div>
                    <CollapsingElement expanded={urlSelectionTypes[0] === MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM}>
                        <div className={isMobile ? "mobile-edit-multilink-section-url-container" : "edit-multilink-section-url-container"}>
                            <EditFieldSectionInput
                                value={urls[0]}
                                setValue={(newValue) => setURL(0, newValue)}
                                title={t('EDIT_SHORT_URL_INPUT_TITLE')}
                                placeholder={t('EDIT_SHORT_URL_INPUT_PLACEHOLDER', { domain: userDetails?.domain })}
                                hasValidation={true}
                                validationStatus={urlsInputValidationState[0].status}
                                validationErrorMessage={t('EDIT_SHORT_URL_ERROR_MESSAGE', { domain: `https://${userDetails?.domain}` })}
                                isValidating={urlsInputValidationState[0].isValidating}
                                titleStyles={{
                                    fontSize: isMobile ? '15px' : '17px',
                                    color: '#7f859e'
                                }}
                                inputStyles={{
                                    width: '100%',
                                    outline: 'none',
                                    border: '1px solid black',
                                    borderRadius: '25px',
                                }}
                            />
                        </div>
                    </CollapsingElement>
                    <CollapsingElement expanded={urlSelectionTypes[0] === MULTILINK_SUBLINK_SELECTION_TYPE.LOOK}>
                        <div className={"edit-multilink-section-add-products-button-padding-container first"}>
                            <MultiSelectStatusButton
                                title={t('EDIT_MULTILINK_SECTION_ADDED_PRODUCTS_BUTTON_TITLE')}
                                status={t('EDIT_MULTILINK_SECTION_ADDED_PRODUCTS_BUTTON_QUANTITY', { count: selectedProducts[0]?.length })}
                                onClick={() => openAddProductsDialog(0, MULTILINK_SUBLINK_SELECTION_TYPE.LOOK)}
                                className={"edit-multilink-section-add-products-button"}
                            />
                        </div>
                    </CollapsingElement>
                </div>}
                {urls.length > 1 && <div className='edit-multilink-sublink-section'>
                    <div className="edit-multilink-section-experience-header-container">
                        <div className="edit-multilink-section-experience-header">{t("EDIT_MULTILINK_SECTION_EXPERIENCE_HEADER", { index: "B" })}</div>
                        <div className={isMobile ? "mobile-edit-multilink-section-experience-options" : "edit-multilink-section-experience-options"}>
                            <div className="edit-multilink-section-device-select" onClick={() => setIsDeviceSelectOpen(prev => [prev[0], !prev[1]])}>
                                <div className="edit-multilink-section-experience-device-option-header">{t("EDIT_MULTILINK_SECTION_DEVICE_HEADER")}</div>
                                <div className="edit-multilink-section-experience-device-option-box">
                                    <PaydinSelect
                                        isOpen={isDeviceSelectOpen[1]}
                                        options={MULTILINK_SUBLINK_DEVICE_OPTIONS_VALUE_ELEMENT}
                                        optionState={deviceOptions[1]}
                                        onOptionClick={(option) => setDeviceOptions(1, option)}
                                        hideArrow={false}
                                        textColor='hsl(229, 23%, 72%)'
                                        width='200px'
                                    />
                                </div>
                            </div>
                            <div className="edit-multilink-section-experience-search-container">
                                <div className="edit-multilink-section-experience-search-box-header">{t("EDIT_MULTILINK_SECTION_TRAFFIC_HEADER")}</div>
                                <div className="edit-multilink-section-search-box">
                                    <input className='edit-multilink-section-search-box-input' value={splittingValues[1]} pattern="[0-9]*" onChange={(value) => updateSplittingValue(1, value)} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                    <AiOutlinePercentage className='edit-multilink-section-search-box-image' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={urlSelectionOptionsRefs[1]} className="edit-multilink-section-url-selection-types">
                        <UrlSelectionType
                            title={t(isMobile ? 'EDIT_MULTILINK_SECTION_URL_SELECTION_LOOK_TYPE_TITLE_MOBILE' : 'EDIT_MULTILINK_SECTION_URL_SELECTION_LOOK_TYPE_TITLE')}
                            image={InstantStoresImage}
                            isSelected={urlSelectionTypes[1] === MULTILINK_SUBLINK_SELECTION_TYPE.LOOK}
                            imageClassName='edit-multilink-section-url-selection-type-image edit-multilink-section-url-selection-svg-type-image'
                            onClick={() => openAddProductsDialog(1, MULTILINK_SUBLINK_SELECTION_TYPE.LOOK)}
                            dimens={urlSelectionOptionDimension[1]}
                            isDisabled={!allowLinkTypeSelection(1, MULTILINK_SUBLINK_TYPES.LOOK)}
                        />
                        <UrlSelectionType
                            title={isProductUrlTypeSelected(1) ? selectedProducts[1][0]?.title : t('EDIT_MULTILINK_SECTION_URL_SELECTION_PRODUCT_TYPE_TITLE')}
                            image={isProductUrlTypeSelected(1) ? selectedProducts[1][0]?.imageUrl : IoShirtOutline}
                            isSelected={isProductUrlTypeSelected(1)}
                            imageClassName='edit-multilink-section-url-selection-type-image'
                            onClick={() => openAddProductsDialog(1, MULTILINK_SUBLINK_SELECTION_TYPE.PRODUCT)}
                            dimens={urlSelectionOptionDimension[1]}
                            hasChangedData={isProductUrlTypeSelected(1)}
                            isDisabled={!allowLinkTypeSelection(1, MULTILINK_SUBLINK_TYPES.SHORT)}
                        />
                        <UrlSelectionType
                            title={isCollectionUrlTypeSelected(1) ? selectedCollections[1][0]?.title : t('EDIT_MULTILINK_SECTION_URL_SELECTION_COLLECTION_TYPE_TITLE')}
                            image={isCollectionUrlTypeSelected(1) ? selectedCollections[1][0]?.images?.medium?.src : ShortUrlSelectionCollectionTypeImage}
                            isSelected={isCollectionUrlTypeSelected(1)}
                            imageClassName='edit-multilink-section-url-selection-type-image edit-multilink-section-url-selection-svg-type-image'
                            onClick={() => openAddCollectionsDialog(1)}
                            dimens={urlSelectionOptionDimension[1]}
                            hasChangedData={isCollectionUrlTypeSelected(1)}
                            isDisabled={!allowLinkTypeSelection(1, MULTILINK_SUBLINK_TYPES.SHORT)}
                        />
                        <UrlSelectionType
                            title={t(isMobile ? 'EDIT_MULTILINK_SECTION_URL_SELECTION_CUSTOM_TYPE_TITLE_MOBILE' : 'EDIT_MULTILINK_SECTION_URL_SELECTION_CUSTOM_TYPE_TITLE')}
                            image={HiOutlineLink}
                            isSelected={urlSelectionTypes[1] === MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM}
                            imageClassName='edit-multilink-section-url-selection-type-image'
                            onClick={() => selectCustomUrl(1)}
                            dimens={urlSelectionOptionDimension[1]}
                            isDisabled={!allowLinkTypeSelection(1, MULTILINK_SUBLINK_TYPES.SHORT)}
                        />
                    </div>
                    <CollapsingElement expanded={urlSelectionTypes[1] === MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM}>
                        <div className={isMobile ? "mobile-edit-multilink-section-url-container second" : "edit-multilink-section-url-container second"}>
                            <EditFieldSectionInput
                                value={urls[1]}
                                setValue={(newValue) => setURL(1, newValue)}
                                title={t('EDIT_SHORT_URL_INPUT_TITLE')}
                                placeholder={t('EDIT_SHORT_URL_INPUT_PLACEHOLDER', { domain: userDetails?.domain })}
                                hasValidation={true}
                                validationStatus={urlsInputValidationState[1].status}
                                validationErrorMessage={t('EDIT_SHORT_URL_ERROR_MESSAGE', { domain: `https://${userDetails?.domain}` })}
                                isValidating={urlsInputValidationState[1].isValidating}
                                titleStyles={{
                                    fontSize: isMobile ? '15px' : '17px',
                                    color: '#7f859e'
                                }}
                                inputStyles={{
                                    width: '100%',
                                    outline: 'none',
                                    border: '1px solid black',
                                    borderRadius: '25px',
                                }}
                            />
                        </div>
                    </CollapsingElement>
                    <CollapsingElement expanded={urlSelectionTypes[1] === MULTILINK_SUBLINK_SELECTION_TYPE.LOOK}>
                        <div className={"edit-multilink-section-add-products-button-padding-container second"}>
                            <MultiSelectStatusButton
                                title={t('EDIT_MULTILINK_SECTION_ADDED_PRODUCTS_BUTTON_TITLE')}
                                status={t('EDIT_MULTILINK_SECTION_ADDED_PRODUCTS_BUTTON_QUANTITY', { count: selectedProducts[1]?.length })}
                                onClick={() => openAddProductsDialog(1, MULTILINK_SUBLINK_SELECTION_TYPE.LOOK)}
                                className={"edit-multilink-section-add-products-button"}
                            />
                        </div>
                    </CollapsingElement>
                </div>}
            </div>
            <div className={isMobile ? "mobile-edit-multilink-section-promocode-container" : "edit-multilink-section-promocode-container"}>
                <EditFieldSectionInput
                    title={t('EDIT_SHORT_PROMOCODE_INPUT_TITLE')}
                    value={promocode}
                    setValue={setPromocode}
                    placeholder={t('EDIT_SHORT_PROMOCODE_INPUT_PLACEHOLDER')}
                    maxLength={EDIT_SHORT_PROMOCODE_INPUT_MAX_LENGTH}
                    isOptional={true}
                    id="promocode"
                />
            </div>
            <div className={isMobile ? "mobile-edit-multilink-section-utm-container" : "edit-multilink-section-utm-container"}>
                {
                    userDetails?.isAdmin && <UTMForm
                        inlinePadding='20px'
                        shouldAddUTMs={shouldAddUTMs}
                        setShouldAddUTMs={setShouldAddUTMs}
                        UTMSource={utmParams.UTMSource}
                        setUTMSource={setUTMSource}
                        UTMMedium={utmParams.UTMMedium}
                        setUTMMedium={setUTMMedium}
                        UTMCampaign={utmParams.UTMCampaign}
                        setUTMCampaign={setUTMCampaign}
                        UTMTerm={utmParams.UTMTerm}
                        setUTMTerm={setUTMTerm}
                        UTMContent={utmParams.UTMContent}
                        setUTMContent={setUTMContent}
                    />
                }
            </div>
            <AddProductsDialog
                isDialogOpen={multiSelectDialogsState.productsDialogShown}
                handleDialogClose={closeAddProductDialog}
                selectedProducts={multiSelectDialogsState.tempSelectedProducts}
                setSelectedProducts={(products) => setUserSelectedProducts(multiSelectDialogsState.index, products, multiSelectDialogsState.urlSelectionType)}
                selectMaxLimit={multiSelectDialogsState.maxLimit}
                selectMinLimit={1}
                searchResultFilter={products => products.filter(product => product?.url !== '')}
                resetSelectedOnFinish={true}
                allowColorOptionSelect={multiSelectDialogsState.maxLimit > 1} // means instantStore
            />
            <AddCollectionsDialog
                isDialogOpen={multiSelectDialogsState.collectionsDialogShown}
                handleDialogClose={closeAddCollectionsDialog}
                selectedCollections={selectedCollections[multiSelectDialogsState.index]}
                setSelectedCollections={(collections) => setUserSelectedCollections(multiSelectDialogsState.index, collections)}
                selectMaxLimit={1}
                selectMinLimit={1}
                searchResultFilter={collections => collections.filter(collection => collection?.url !== '')}
            />
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={false}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={false}
                onRightButtonClick={dialogState.rightButtonClickHandler}
            />
        </div>
    )
}