import React, { useEffect, useRef, useState } from 'react'
import './AppearancePage.css'
import { useTranslation } from 'react-i18next'
import AccountSection from 'components/AccountSection/AccountSection'
import ImageBox from 'components/ImageBox/ImageBox'
import { isMobile } from 'react-device-detect'
import {
    APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS,
    PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS,
    RESIZE_IMAGE_MAX_HEIGHT,
    RESIZE_IMAGE_MAX_WIDTH,
    SALES_AND_DISCOUNT_STRIP_SENTENCE_1_INPUT_MAX_LENGTH,
    SALES_AND_DISCOUNT_STRIP_SENTENCE_2_INPUT_MAX_LENGTH,
    SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR,
    APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS,
    APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_BORDER_WIDTH_PX,
    APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_SELECTED_BORDER_WIDTH_PX,
    APPEARANCE_PAGE_LOGO_SECTION_ID,
    APPEARANCE_PAGE_THEME_SECTION_ID,
    OPTION_VALUE_MAPPER_OPTION_TYPES,
    APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS,
    PREORDER_TEXT_INPUT_MAX_LENGTH,
} from 'constants/general.constants'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { uploadFile } from 'utils/utils'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import AddMedia from 'components/AddMedia/AddMedia'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { UpdateLinkStyles, UpdateStoreAppearanceSettings, getOptionsMap, updateOptionsMap } from 'api/store/store'
import ImageCropDialog from 'dialogs/ImageCropDialog/ImageCropDialog'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import PaydinColorPicker from 'components/PaydinColorPicker/PaydinColorPicker'
import StoreSettingsLinkStyleSection from 'components/StoreSettingsLinkStyleSection/StoreSettingsLinkStyleSection'
import AppearanceOptionPicker from 'components/AppearanceOptionPicker/AppearanceOptionPicker'
import { resizeImage } from 'services/imageUtils'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import RunningMessagesStrip from 'components/RunningMessagesStrip/RunningMessagesStrip'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import { useHistoryContext } from 'contexts/History'
import SaveChangesStrip from 'components/SaveChangesStrip/SaveChangesStrip'
import CollapsingElement from 'components/CollapsingElement/CollapsingElement'
import { scrollToId } from 'services/SystemService'
import OptionValueMapper from 'components/OptionValueMapper/OptionValueMapper'
import { HOME_PAGE_URL } from 'constants/routes.constants'

export default function AppearancePage() {
    const { t } = useTranslation()
    const {
        userDetails,
        setUserDetails,
        hasShopifyStore
    } = useUserDetailsContext()
    const {
        fetchProductsOptionsMap,
        updateProductsOptionsMap,
    } = useGeneralDataContext()
    const history = useHistoryContext()

    const originalShopWebsite = (userDetails?.domain && userDetails?.domain !== '') ? `${userDetails?.domain}` : ''
    const originalShippingAndReturnUrl = (userDetails?.shippingAndReturnsUrl && userDetails?.shippingAndReturnsUrl !== '') ? userDetails?.shippingAndReturnsUrl.replace(t('HTTPS_HOST_URL_PREFIX'), '') : ''

    const [businessDataState, setBusinessDataState] = useState({
        logoUrl: userDetails?.businessLogo,
        internationalRedirectUrl: originalShopWebsite,
        shippingAndReturnsUrl: originalShippingAndReturnUrl
    })
    const [buttonLoadersState, setButtonLoadersState] = useState({
        uploadLogoUrl: false,
        removeLogoUrl: false
    })
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        leftButtonText: '',
        rightButtonText: ''
    })
    const [isImageCropDialogOpen, setIsImageCropDialogOpen] = useState(false)
    const [cropperTempImage, setCropperTempImage] = useState('')
    const [approvalMessageState, setApprovalMessageState] = useState({
        didChangesSaved: false,
        message: ''
    })

    const [inputState, setInputState] = useState({
        linkStyle: userDetails?.theme?.link,
        productImageStyle: userDetails?.theme?.productImageStyle,
        salesAndDiscountStrip: userDetails?.theme?.salesAndDiscountStrip,
        inventorySettings: userDetails?.theme?.inventorySettings
    })
    const [saveChangesStripState, setSaveChangesStripState] = useState({
        isSaving: false,
        isShown: false
    })
    const [saveButtonState, setSaveButtonState] = useState({
        isAbleToSave: false,
        isSaving: false
    })
    const [mobileSaveButtonState, setMobileSaveButtonState] = useState({
        isAbleToSave: false,
        isSaving: false
    })
    const [optionMap, setOptionMap] = useState({})
    const [isOptionMapChanged, setIsOptionMapChanged] = useState(false)
    const [isThemeChanged, setIsThemeChanged] = useState(false)
    const [isDiscardTriggered, setIsDiscardTriggered] = useState(false)

    const fileInputRef = useRef(null)

    const checkoutButtonPreviewStyles = {
        backgroundColor: inputState?.linkStyle.checkoutButtonBackgroundColor,
        color: inputState?.linkStyle?.checkoutButtonFontColor,
        borderColor: inputState?.linkStyle?.checkoutButtonBorderColor,
        borderRadius: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS.find(option => option?.key === inputState?.linkStyle?.checkoutButtonShape).value
    }

    useEffect(() => {
        if (hasShopifyStore()) {
            scrollToId()
            fetchProductsOptionsMap(originalOptionMap => setOptionMap(originalOptionMap), true)
        } else {
            history.replace(HOME_PAGE_URL)
        }
    }, [])

    useEffect(() => {
        const isThemeChanged = (
            userDetails?.theme?.link?.cartButtonImage !== inputState.linkStyle.cartButtonImage ||
            userDetails?.theme?.link?.checkoutButtonShape !== inputState.linkStyle.checkoutButtonShape ||
            userDetails?.theme?.link?.checkoutButtonBackgroundColor !== inputState.linkStyle.checkoutButtonBackgroundColor ||
            userDetails?.theme?.link?.checkoutButtonBorderColor !== inputState.linkStyle.checkoutButtonBorderColor ||
            userDetails?.theme?.link?.checkoutButtonFontColor !== inputState.linkStyle.checkoutButtonFontColor ||
            userDetails?.theme?.productImageStyle?.imageBorderRadius !== inputState.productImageStyle.imageBorderRadius ||
            (
                userDetails?.theme?.salesAndDiscountStrip?.isSalesAndDiscountStripShown !== inputState.salesAndDiscountStrip.isSalesAndDiscountStripShown ||
                userDetails?.theme?.salesAndDiscountStrip?.isSalesAndDiscountStripShown === inputState.salesAndDiscountStrip.isSalesAndDiscountStripShown && (

                    ![userDetails?.theme?.salesAndDiscountStrip?.salesAndDiscountStripSentence1].includes(inputState.salesAndDiscountStrip.salesAndDiscountStripSentence1) ||
                    ![userDetails?.theme?.salesAndDiscountStrip?.salesAndDiscountStripSentence2].includes(inputState.salesAndDiscountStrip.salesAndDiscountStripSentence2)
                )
            ) ||
            userDetails?.theme?.salesAndDiscountStrip?.salesAndDiscountStripBackgroundColor !== inputState.salesAndDiscountStrip.salesAndDiscountStripBackgroundColor ||
            userDetails?.theme?.salesAndDiscountStrip?.salesAndDiscountStripFontColor !== inputState.salesAndDiscountStrip.salesAndDiscountStripFontColor ||
            (
                userDetails?.theme?.inventorySettings?.isPreorderTextShown !== inputState.inventorySettings.isPreorderTextShown ||
                (
                    userDetails?.theme?.inventorySettings?.isPreorderTextShown === inputState.inventorySettings.isPreorderTextShown &&
                    userDetails?.theme?.inventorySettings?.preorderText !== inputState.inventorySettings.preorderText
                )
            )
        )

        setIsThemeChanged(isThemeChanged)
        setSaveChangesStripState(prev => ({
            ...prev,
            isShown: isThemeChanged || isOptionMapChanged
        }))
    }, [inputState, isOptionMapChanged])

    useEffect(() => {
        setSaveButtonState(prev => ({
            ...prev,
            isAbleToSave: saveChangesStripState.isShown
        }))
        setMobileSaveButtonState(prev => ({
            ...prev,
            isAbleToSave: saveChangesStripState.isShown
        }))
    }, [saveChangesStripState])

    function showDialog(title, message, leftButtonText, rightButtonText, rightButtonClickHandler = () => { }) {
        setDialogState({
            isOpen: true,
            title,
            message,
            leftButtonText,
            rightButtonText,
            rightButtonClickHandler
        })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function updateStoreData(logoUrl, onSuccess = () => { }, onFailure = () => { }) {
        UpdateStoreAppearanceSettings(userDetails?.businessId, logoUrl)
            .then(onSuccess)
            .catch(onFailure)
    }

    function onStripSaveClick() {
        setSaveChangesStripState(prev => ({
            ...prev,
            isSaving: true
        }))

        saveAppearance()
    }

    function onSaveButtonClick() {
        setSaveButtonState(prev => ({
            ...prev,
            isSaving: true
        }))

        saveAppearance()
    }

    function onMobileSaveButtonClick() {
        setMobileSaveButtonState(prev => ({
            ...prev,
            isSaving: true
        }))

        saveAppearance()
    }

    function saveAppearance() {
        if (isOptionMapChanged) {
            updateOptionsMap(optionMap)
                .then(() => {
                    setSaveChangesStripState({
                        isSaving: false,
                        isShown: false
                    })
                    setMobileSaveButtonState(prev => ({
                        ...prev,
                        isSaving: false
                    }))
                    showApprovalMessage(t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_SAVED_CHANGES_APPROVAL_MESSAGE'))
                    updateProductsOptionsMap(optionMap)
                    setIsOptionMapChanged(false)
                })
                .catch(error => {
                    setSaveChangesStripState({
                        isSaving: false,
                        isShown: false
                    })
                    showDialog(
                        t('APPEARANCE_PAGE_GENERAL_ERROR_DIALOG_TITLE'),
                        t('APPEARANCE_PAGE_GENERAL_ERROR_DIALOG_MESSAGE'),
                        t('APPEARANCE_PAGE_GENERAL_ERROR_DIALOG_LEFT_BUTTON_TEXT'),
                        null
                    )
                })
                .finally(() => {
                    if (!isThemeChanged) {
                        setSaveChangesStripState(prev => ({
                            ...prev,
                            isSaving: false
                        }))
                        setSaveButtonState(prev => ({
                            ...prev,
                            isSaving: false
                        }))
                        setMobileSaveButtonState(prev => ({
                            ...prev,
                            isSaving: false
                        }))
                    }
                })
        }

        if (isThemeChanged) {
            if (inputState.salesAndDiscountStrip?.salesAndDiscountStripSentence1 === '' && inputState.salesAndDiscountStrip?.salesAndDiscountStripSentence2 === '') {
                inputState.salesAndDiscountStrip.isSalesAndDiscountStripShown = false
                inputState.salesAndDiscountStrip.salesAndDiscountStripSentence1 = ''
                inputState.salesAndDiscountStrip.salesAndDiscountStripSentence2 = ''
            }

            const themeObject = {
                ...inputState.linkStyle,
                ...inputState.salesAndDiscountStrip,
                ...inputState.productImageStyle,
                ...inputState.inventorySettings,
            }
            UpdateLinkStyles(userDetails?.businessId, themeObject)
                .then(_ => {
                    setSaveChangesStripState({
                        isSaving: false,
                        isShown: false
                    })
                    showApprovalMessage(t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_SAVED_CHANGES_APPROVAL_MESSAGE'))
                    setUserDetails(prev => ({
                        ...prev,
                        theme: {
                            ...prev.theme,
                            isInitial: false,
                            link: inputState.linkStyle,
                            productImageStyle: inputState.productImageStyle,
                            salesAndDiscountStrip: inputState.salesAndDiscountStrip,
                            inventorySettings: inputState.inventorySettings,
                        }
                    }))
                })
                .catch(error => {
                    setSaveChangesStripState({
                        isSaving: false,
                        isShown: false
                    })
                    showDialog(
                        t('APPEARANCE_PAGE_GENERAL_ERROR_DIALOG_TITLE'),
                        t('APPEARANCE_PAGE_GENERAL_ERROR_DIALOG_MESSAGE'),
                        t('APPEARANCE_PAGE_GENERAL_ERROR_DIALOG_LEFT_BUTTON_TEXT'),
                        null
                    )
                })
                .finally(() => {
                    setSaveChangesStripState(prev => ({
                        ...prev,
                        isSaving: false
                    }))
                    setSaveButtonState(prev => ({
                        ...prev,
                        isSaving: false
                    }))
                    setMobileSaveButtonState(prev => ({
                        ...prev,
                        isSaving: false
                    }))
                })
        }
    }

    async function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            const resizedImage = await resizeImage(event.target.files[0], RESIZE_IMAGE_MAX_WIDTH, RESIZE_IMAGE_MAX_HEIGHT)
            setCropperTempImage(URL.createObjectURL(resizedImage))
            setIsImageCropDialogOpen(true)
        }
    }

    function uploadLogoFromDevice() {
        fileInputRef.current.value = null
        fileInputRef?.current?.click()
    }

    function uploadLogo(importedFile) {
        setButtonLoadersState(prev => ({
            ...prev,
            uploadLogoUrl: true
        }))
        setBusinessDataState(prev => ({
            ...prev,
            logoUrl: importedFile
        }))

        uploadFile(importedFile, userDetails.username, url => {
            updateStoreData(url, response => {
                setButtonLoadersState(prev => ({
                    ...prev,
                    uploadLogoUrl: false
                }))
                setUserDetails(prev => ({
                    ...prev,
                    businessLogo: url
                }))
                showApprovalMessage(t('APPEARANCE_PAGE_SHOP_LOGO_SECTION_SAVED_CHANGES_APPROVAL_MESSAGE'))
            }, error => {
                console.log(error)
                setButtonLoadersState(prev => ({
                    ...prev,
                    uploadLogoUrl: false
                }))
                showDialog(
                    t('APPEARANCE_PAGE_UPLOAD_LOGO_ERROR_DIALOG_TITLE'),
                    '',
                    t('APPEARANCE_PAGE_UPLOAD_LOGO_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
                    null
                )
            })
        }, error => {
            console.log(error)
            setButtonLoadersState(prev => ({
                ...prev,
                uploadLogoUrl: false
            }))
            showDialog(
                t('APPEARANCE_PAGE_UPLOAD_LOGO_ERROR_DIALOG_TITLE'),
                '',
                t('APPEARANCE_PAGE_UPLOAD_LOGO_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
                null
            )
        })
    }

    function removeLogo() {
        setButtonLoadersState(prev => ({
            ...prev,
            removeLogoUrl: true
        }))
        setBusinessDataState(prev => {
            const updatedBusinessData = {
                ...prev,
                logoUrl: ''
            }
            fileInputRef.current.value = null

            updateStoreData(updatedBusinessData.logoUrl, response => {
                setButtonLoadersState(prev => ({
                    ...prev,
                    removeLogoUrl: false
                }))
                setUserDetails(prev => ({
                    ...prev,
                    businessLogo: ''
                }))
            }, error => {
                console.log(error)
                setButtonLoadersState(prev => ({
                    ...prev,
                    removeLogoUrl: false
                }))
            })

            return updatedBusinessData
        })
    }

    function showRemoveLogoConfirmationDialog() {
        showDialog(
            t('APPEARANCE_PAGE_REMOVE_LOGO_CONFIRMATION_DIALOG_TITLE'),
            '',
            t('APPEARANCE_PAGE_REMOVE_LOGO_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
            t('APPEARANCE_PAGE_REMOVE_LOGO_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
            removeLogo
        )
    }

    function onApplyCrop(croppedImage, crop, zoom, aspectRatio) {
        uploadLogo(croppedImage)
        setIsImageCropDialogOpen(false)
    }

    function closeImageCropDialog() {
        setIsImageCropDialogOpen(false)
    }

    function setCheckoutBackgroundColor(color) {
        setInputState(prev => ({
            ...prev,
            linkStyle: {
                ...prev.linkStyle,
                checkoutButtonBackgroundColor: color
            }
        }))
    }

    function setCheckoutBorderColor(color) {
        setInputState(prev => ({
            ...prev,
            linkStyle: {
                ...prev.linkStyle,
                checkoutButtonBorderColor: color
            }
        }))
    }

    function setCartButtonImageKey(key) {
        setInputState(prev => ({
            ...prev,
            linkStyle: {
                ...prev.linkStyle,
                cartButtonImage: key
            }
        }))
    }

    function setCheckoutButtonShapeKey(key) {
        setInputState(prev => ({
            ...prev,
            linkStyle: {
                ...prev.linkStyle,
                checkoutButtonShape: key
            }
        }))
    }

    function setProductImageStyleKey(key) {
        setInputState(prev => ({
            ...prev,
            productImageStyle: {
                ...prev.productImageStyle,
                imageBorderRadius: key
            }
        }))
    }

    function setCheckoutButtonFontColor(color) {
        setInputState(prev => ({
            ...prev,
            linkStyle: {
                ...prev.linkStyle,
                checkoutButtonFontColor: color
            }
        }))
    }


    function setSalesAndDiscountStripBackgroundColor(color) {
        setInputState(prev => ({
            ...prev,
            salesAndDiscountStrip: {
                ...prev.salesAndDiscountStrip,
                salesAndDiscountStripBackgroundColor: color
            }
        }))
    }

    function setSalesAndDiscountStripFontColor(color) {
        setInputState(prev => ({
            ...prev,
            salesAndDiscountStrip: {
                ...prev.salesAndDiscountStrip,
                salesAndDiscountStripFontColor: color
            }
        }))
    }

    function setSalesAndDiscountStripSentence1(text) {
        setInputState(prev => ({
            ...prev,
            salesAndDiscountStrip: {
                ...prev.salesAndDiscountStrip,
                salesAndDiscountStripSentence1: text
            }
        }))
    }

    function setSalesAndDiscountStripSentence2(text) {
        setInputState(prev => ({
            ...prev,
            salesAndDiscountStrip: {
                ...prev.salesAndDiscountStrip,
                salesAndDiscountStripSentence2: text
            }
        }))
    }

    function showApprovalMessage(message) {
        setApprovalMessageState({
            message,
            didChangesSaved: true
        })
    }

    function hideApprovalMessage() {
        setApprovalMessageState(prev => ({
            ...prev,
            didChangesSaved: false
        }))
    }

    function setIsSalesAndDiscountStripSectionContentShown(value) {
        setInputState(prev => ({
            ...prev,
            salesAndDiscountStrip: {
                ...prev.salesAndDiscountStrip,
                isSalesAndDiscountStripShown: value
            }
        }))
    }

    function setIsPreorderSectionContentShown(value) {
        setInputState(prev => ({
            ...prev,
            inventorySettings: {
                ...prev.inventorySettings,
                isPreorderTextShown: value
            }
        }))
    }

    function setPreorderText(text) {
        setInputState(prev => ({
            ...prev,
            inventorySettings: {
                ...prev.inventorySettings,
                preorderText: text
            }
        }))
    }

    function resetData() {
        fetchProductsOptionsMap(originalOptionMap => setOptionMap(originalOptionMap), true)
        setIsOptionMapChanged(false)
        setIsDiscardTriggered(true)
        setTimeout(() => {
            setIsDiscardTriggered(false)
        }, 100)
        setInputState({
            linkStyle: userDetails?.theme?.link,
            productImageStyle: userDetails?.theme?.productImageStyle,
            salesAndDiscountStrip: userDetails?.theme?.salesAndDiscountStrip,
            inventorySettings: userDetails?.theme?.inventorySettings
        })
    }

    function updateOptionMap(optionName, selectedOptionType, modifiedObjectKey, value) {
        if (!optionName) {
            return
        }

        if (selectedOptionType) {
            setIsOptionMapChanged(true)
            setOptionMap(prev => {
                const newOptionMap = { ...prev }

                newOptionMap[optionName].type = selectedOptionType
                if (modifiedObjectKey && value) {
                    if (selectedOptionType === OPTION_VALUE_MAPPER_OPTION_TYPES.TEXT) {
                        newOptionMap[optionName].values[modifiedObjectKey].text_value = value
                    } else {
                        newOptionMap[optionName].values[modifiedObjectKey].hex_value = value
                    }
                }

                return newOptionMap
            })
        }
    }

    return (
        <div className={isMobile ? 'mobile-appearance-page-container' : 'appearance-page-container'}>
            <SaveChangesStrip
                isStripShown={saveChangesStripState.isShown}
                isSaving={saveChangesStripState.isSaving}
                onDiscardClick={resetData}
                onSaveClick={onStripSaveClick}
            />
            {
                isMobile ? <ContentDialogTopbar
                    title={t('APPEARANCE_PAGE_TITLE')}
                    onBackButtonClick={() => history.goBack()}
                    rightElement={<>
                        <LoaderButton
                            className='page-view-topbar-save-button'
                            buttonText={t('LINK_VIEW_TOPBAR_SAVE_BUTTON_TEXT')}
                            isLoading={mobileSaveButtonState.isSaving}
                            loaderCircleColor='#0095f6'
                            isDisabled={!mobileSaveButtonState.isAbleToSave || mobileSaveButtonState.isSaving}
                            onClick={onMobileSaveButtonClick}
                        />
                    </>}
                /> : <div className="appearance-page-title dashboard-page-title">{t('APPEARANCE_PAGE_TITLE')}</div>
            }
            <div className='appearance-page-content'>
                <AccountSection
                    title={t('APPEARANCE_PAGE_STORE_DETAILS_SECTION_TITLE')}
                    id={APPEARANCE_PAGE_LOGO_SECTION_ID}
                >
                    <>
                        <div className="appearance-page-store-details-section-store-logo-container">
                            <div className="settings-pages-subsection-title">{t('APPEARANCE_PAGE_STORE_DETAILS_SECTION_STORE_LOGO_TITLE')}</div>
                            <div className="appearance-page-store-details-section-update-logo-container">
                                <div className="appearance-page-store-details-section-update-logo-add-logo-restriction-container">
                                    <div className={`${isMobile ? 'mobile-appearance-page-store-details-section-update-logo-add-logo-container' : 'appearance-page-store-details-section-update-logo-add-logo-container'} settings-pages-subsection-frame`}>
                                        {
                                            businessDataState.logoUrl ? <ImageBox
                                                image={businessDataState.logoUrl}
                                                className='appearance-page-store-details-section-update-logo-image-container'
                                                imageClassName='appearance-page-store-details-section-update-logo-image'
                                            /> : <AddMedia onAddMedia={uploadLogoFromDevice} aspectRatio='5 / 2' />
                                        }
                                    </div>
                                    <div className="appearance-page-store-details-section-update-logo-restriction">{t('APPEARANCE_PAGE_STORE_DETAILS_SECTION_LOGO_SIZE_INSTRACTION')}</div>
                                    <div className="appearance-page-store-details-section-update-logo-restriction">{t('APPEARANCE_PAGE_STORE_DETAILS_SECTION_LOGO_RESTRICTION_TEXT')}</div>
                                </div>
                                <div className="appearance-page-store-details-section-update-logo-buttons-container" style={{ ...(isMobile ? { width: '100%' } : {}) }}>
                                    <LoaderButton
                                        className='appearance-page-store-details-section-update-logo-button'
                                        buttonText={t('APPEARANCE_PAGE_STORE_DETAILS_SECTION_UPLOAD_LOGO_BUTTON_TEXT')}
                                        isLoading={buttonLoadersState.uploadLogoUrl}
                                        onClick={uploadLogoFromDevice}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                    <LoaderButton
                                        className='appearance-page-store-details-section-remove-button'
                                        buttonText={t('APPEARANCE_PAGE_STORE_DETAILS_SECTION_REMOVE_BUTTON_TEXT')}
                                        isDisabled={!businessDataState.logoUrl}
                                        isLoading={buttonLoadersState.removeLogoUrl}
                                        onClick={showRemoveLogoConfirmationDialog}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                </AccountSection>
                <AccountSection
                    title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_TITLE')}
                    id={APPEARANCE_PAGE_THEME_SECTION_ID}
                    sectionContentClassName='appearance-page-link-style-section'
                >
                    <>
                        <div className='appearance-checkout-button-style-container'>
                            <div className="appearance-checkout-button-style-fields">
                                <StoreSettingsLinkStyleSection
                                    title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_SHAPE_SUBSECTION_TITLE')}
                                >
                                    <AppearanceOptionPicker
                                        selectedOption={inputState.linkStyle.checkoutButtonShape}
                                        setSelectedOption={setCheckoutButtonShapeKey}
                                        options={APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS}
                                        selectedOptionBorderColor='#2c6ecb'
                                    />
                                </StoreSettingsLinkStyleSection>
                                <StoreSettingsLinkStyleSection
                                    title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BACKGROUND_COLOR_SECTION_TITLE')}
                                >
                                    <PaydinColorPicker
                                        title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BACKGROUND_COLOR_SUBSECTION_SUBTITLE')}
                                        color={inputState.linkStyle.checkoutButtonBackgroundColor}
                                        setColor={setCheckoutBackgroundColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                                <StoreSettingsLinkStyleSection
                                    title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BORDER_COLOR_SECTION_TITLE')}
                                >
                                    <PaydinColorPicker
                                        title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_BORDER_COLOR_SUBSECTION_SUBTITLE')}
                                        color={inputState.linkStyle.checkoutButtonBorderColor}
                                        setColor={setCheckoutBorderColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                                <StoreSettingsLinkStyleSection
                                    title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_FONT_COLOR_SUBSECTION_TITLE')}
                                >
                                    <PaydinColorPicker
                                        title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CHECKOUT_BUTTON_FONT_COLOR_SUBSECTION_SUBTITLE')}
                                        color={inputState.linkStyle.checkoutButtonFontColor}
                                        setColor={setCheckoutButtonFontColor}
                                    />
                                </StoreSettingsLinkStyleSection>
                            </div>
                            {!isMobile && <div className="appearance-preview">
                                <div className="appearance-preview-frame">
                                    <div className="appearance-checkout-button-preview-element appearance-preview-element" style={checkoutButtonPreviewStyles}>
                                        <div className="appearance-checkout-button-preview-element-text">Checkout</div>
                                        <div className="appearance-checkout-button-preview-element-dot" style={{ backgroundColor: checkoutButtonPreviewStyles.color }}></div>
                                        <div className="appearance-checkout-button-preview-element-price">$456</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </>
                </AccountSection>
                <AccountSection
                    title={t('APPEARANCE_PAGE_CART_STYLE_SECTION_TITLE')}
                    sectionContentClassName='appearance-page-link-style-section'
                >
                    <StoreSettingsLinkStyleSection
                        title={t('APPEARANCE_PAGE_SHOP_LINK_STYLE_SECTION_CART_BUTTON_IMAGE_SUBSECTION_TITLE')}
                    >
                        <AppearanceOptionPicker
                            selectedOption={inputState.linkStyle.cartButtonImage}
                            setSelectedOption={setCartButtonImageKey}
                            options={APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS}
                            selectedOptionBorderColor='#2c6ecb'
                        />
                    </StoreSettingsLinkStyleSection>
                </AccountSection>
                <AccountSection
                    title={t('APPEARANCE_PAGE_PRODUCT_IMAGE_STYLE_SECTION_TITLE')}
                    sectionContentClassName='appearance-page-link-style-section'
                >
                    <>
                        <div className='appearance-product-image-style-container'>
                            <AppearanceOptionPicker
                                selectedOption={inputState.productImageStyle.imageBorderRadius}
                                setSelectedOption={setProductImageStyleKey}
                                options={APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS}
                                selectedOptionBorderColor='green'
                                gap={isMobile ? '10px' : '25px'}
                                justifyContent={isMobile && 'space-around'}
                                valueAsBorderRadius={true}
                                borderWidthPx={APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_BORDER_WIDTH_PX}
                                selectedBorderWidthPx={APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_SELECTED_BORDER_WIDTH_PX}
                            />
                        </div>
                    </>
                </AccountSection>
                <AccountSection
                    title={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_TITLE')}
                    sectionContentClassName='appearance-page-sales-and-discount-strip-style-section'
                    hasToggleSwitch={true}
                    isContentShown={inputState.salesAndDiscountStrip.isSalesAndDiscountStripShown}
                    setIsContentShown={setIsSalesAndDiscountStripSectionContentShown}
                    shouldControlContent={false}
                >
                    <div className="appearance-sales-and-discount-strip-style-container">
                        <div className="appearance-subsection-description-container">
                            <div className="appearance-subsection-description">{t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_PREVIEW_DESCRIPTION')}</div>
                        </div>
                        <CollapsingElement
                            expanded={inputState.salesAndDiscountStrip.isSalesAndDiscountStripShown}
                        >
                            <StoreSettingsLinkStyleSection
                                title={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_PREVIEW_SUBSECTION_TITLE')}
                            >
                                <div className="appearance-sales-and-discount-strip-preview">
                                    <div className="appearance-sales-and-discount-strip-preview-frame">
                                        <RunningMessagesStrip
                                            messages={
                                                inputState.salesAndDiscountStrip.salesAndDiscountStripSentence1 || inputState.salesAndDiscountStrip.salesAndDiscountStripSentence2 ? [
                                                    ...(inputState.salesAndDiscountStrip.salesAndDiscountStripSentence1 ? [inputState.salesAndDiscountStrip.salesAndDiscountStripSentence1] : []),
                                                    ...(inputState.salesAndDiscountStrip.salesAndDiscountStripSentence2 ? [inputState.salesAndDiscountStrip.salesAndDiscountStripSentence2] : [])
                                                ] : [
                                                    t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_EXAMPLE_SENTENCE_1'),
                                                    t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_EXAMPLE_SENTENCE_2')
                                                ]}
                                            backgroundColor={inputState.salesAndDiscountStrip.salesAndDiscountStripBackgroundColor}
                                            fontColor={inputState.salesAndDiscountStrip.salesAndDiscountStripFontColor}
                                        />
                                    </div>
                                </div>
                            </StoreSettingsLinkStyleSection>
                            <div className="appearance-sales-and-discount-strip-style-fields">
                                <div className={isMobile ? "mobile-appearance-sales-and-discount-strip-style-colors-container" : "appearance-sales-and-discount-strip-style-colors-container"}>
                                    <StoreSettingsLinkStyleSection
                                        title={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_BACKGROUND_COLOR_SUBSECTION_TITLE')}
                                    >
                                        <PaydinColorPicker
                                            title={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_BACKGROUND_COLOR_SUBSECTION_SUBTITLE')}
                                            color={inputState.salesAndDiscountStrip.salesAndDiscountStripBackgroundColor}
                                            setColor={setSalesAndDiscountStripBackgroundColor}
                                        />
                                    </StoreSettingsLinkStyleSection>
                                    <StoreSettingsLinkStyleSection
                                        title={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_FONT_COLOR_SUBSECTION_TITLE')}
                                    >
                                        <PaydinColorPicker
                                            title={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_FONT_COLOR_SUBSECTION_SUBTITLE')}
                                            color={inputState.salesAndDiscountStrip.salesAndDiscountStripFontColor}
                                            setColor={setSalesAndDiscountStripFontColor}
                                        />
                                    </StoreSettingsLinkStyleSection>
                                </div>
                                <StoreSettingsLinkStyleSection
                                    title={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_SENTENCES_SUBSECTION_TITLE')}
                                >
                                    <div className="appearance-sales-and-discount-strip-style-sentences-container">
                                        <EditFieldSectionInput
                                            placeholder={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_SENTENCE_1_PLACEHOLDER')}
                                            maxLength={SALES_AND_DISCOUNT_STRIP_SENTENCE_1_INPUT_MAX_LENGTH}
                                            value={inputState.salesAndDiscountStrip.salesAndDiscountStripSentence1}
                                            setValue={setSalesAndDiscountStripSentence1}
                                            id="appearance-sales-and-discount-strip-style-sentences-container-1"
                                        />
                                        <EditFieldSectionInput
                                            placeholder={t('APPEARANCE_PAGE_SHOP_SALES_AND_DISCOUNT_STYLE_SECTION_STRIP_SENTENCE_2_PLACEHOLDER')}
                                            maxLength={SALES_AND_DISCOUNT_STRIP_SENTENCE_2_INPUT_MAX_LENGTH}
                                            value={inputState.salesAndDiscountStrip.salesAndDiscountStripSentence2}
                                            setValue={setSalesAndDiscountStripSentence2}
                                            id="appearance-sales-and-discount-strip-style-sentences-container-2"
                                        />
                                    </div>
                                </StoreSettingsLinkStyleSection>
                            </div>
                        </CollapsingElement>
                    </div>
                </AccountSection>
                <AccountSection
                    title={t('APPEARANCE_PAGE_PRODUCT_PREORDER_SETTINGS_SECTION_TITLE')}
                    sectionContentClassName='appearance-page-preorder-style-section'
                    hasToggleSwitch={true}
                    isContentShown={inputState.inventorySettings.isPreorderTextShown}
                    setIsContentShown={setIsPreorderSectionContentShown}
                    shouldControlContent={false}
                >
                    <div className='appearance-page-preorder-style-container'>
                        <div className="appearance-subsection-description-container">
                            <div className="appearance-subsection-description">{t('APPEARANCE_PAGE_PRODUCT_PREORDER_SETTINGS_SECTION_PREVIEW_DESCRIPTION')}</div>
                        </div>
                        <CollapsingElement
                            expanded={inputState.inventorySettings.isPreorderTextShown}
                            >
                            <div className="appearance-page-preorder-style-text-container">
                                <EditFieldSectionInput
                                    placeholder={t('APPEARANCE_PAGE_PRODUCT_PREORDER_SETTINGS_SECTION_INPUT_PLACEHOLDER')}
                                    maxLength={PREORDER_TEXT_INPUT_MAX_LENGTH}
                                    value={inputState.inventorySettings.preorderText}
                                    setValue={setPreorderText}
                                    id="appearance-page-preorder-style-text"
                                    />
                            </div>
                        </CollapsingElement>
                    </div>
                </AccountSection>
                {
                    Object.keys(optionMap).map(optionName => <AccountSection
                        key={optionName}
                        title={t('OPTION_VALUE_MAPPER_TITLE', { optionName })}
                    >
                        <OptionValueMapper
                            option={JSON.stringify(optionMap[optionName])}
                            updateOptionMap={(selectedOptionType, valueName = null, value = null) => updateOptionMap(optionName, selectedOptionType, valueName, value)}
                            isDiscardTriggered={isDiscardTriggered}
                        />
                    </AccountSection>)
                }
                {
                    !isMobile && <LoaderButton
                        className={'save-changes-button'}
                        isLoading={saveButtonState.isSaving}
                        isDisabled={!saveButtonState.isAbleToSave || saveButtonState.isSaving}
                        buttonText={t('SETTINGS_PAGE_SAVE_CHANGES_BUTTON_TEXT')}
                        onClick={onSaveButtonClick}
                    />
                }
            </div>
            <input ref={fileInputRef} type="file" accept="image/jpeg,image/png,image/webp" onChange={onImageChange} style={{ display: 'none' }} />
            <ImageCropDialog
                isDialogOpen={isImageCropDialogOpen}
                handleDialogClose={closeImageCropDialog}
                image={cropperTempImage}
                aspectRatiosArray={PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS}
                isLogoCropper={true}
                onCropDone={onApplyCrop}
            />
            <PaydinDialog
                title={dialogState.title}
                message={dialogState.message}
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={closeDialog}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                isLeftButtonWithLoader={false}
            />
            <ApprovalMessage
                isMessageShown={approvalMessageState.didChangesSaved}
                closeMessage={hideApprovalMessage}
                text={approvalMessageState.message}
            />
        </div>
    )
}