import React, { useState } from 'react'
import './ShopboardSocialMediaPost.css'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import ShopboardSocialMediaPostProductsTag from 'components/ShopboardSocialMediaPostProductsTag/ShopboardSocialMediaPostProductsTag'
import { isMobile } from 'react-device-detect'
import Tooltip from 'components/Tooltip/Tooltip'

export default function ShopboardSocialMediaPost({
    id = '',
    social = '',
    image = '',
    isVisible = true,
    taggedProducts = [],
    onTagProducts = () => { }
}) {
    const { t } = useTranslation()
    const {
        togglePostVisibility,
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()

    const {
        openFreeTrialDialog,
    } = useGeneralDataContext()

    const [visible, setVisible] = useState(isVisible)

    function toggleVisibility() {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else {
            setVisible(prev => !prev)
            togglePostVisibility(social, id, !isVisible)
        }
    }

    function onTagProductsClicked() {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else {
            onTagProducts(social, id, taggedProducts)
        }
    }

    return (
        <div className={isMobile ? 'mobile-shopboard-social-media-post' : 'shopboard-social-media-post'}>
            {!visible && <div className='shopboard-social-media-post-mask'></div>}
            <div className={isMobile ? "mobile-shopboard-social-media-post-top-section" : "shopboard-social-media-post-top-section"}>
                <Tooltip
                    image={visible ? <AiFillEye className='shopboard-social-media-post-visible-image' onClick={toggleVisibility} /> : <AiFillEyeInvisible className='shopboard-social-media-post-visible-image invisible' onClick={toggleVisibility} />}
                    tooltipText={visible ? 'Hide post from shoppable Feed' : 'Show post from shoppable Feed'}
                    displayBubble={!isMobile}
                />
                <ImageBox
                    image={image}
                    className={isMobile ? "mobile-shopboard-social-media-post-image" : "shopboard-social-media-post-image"}
                    isImageFitCover={true}
                    showImageBorderRadius={true}
                    borderRadiusPx={5}
                    showBorder={true}
                    border='1px solid #e2e2e2'
                />
            </div>
            {
                taggedProducts.length === 0 ?
                    <div className={isMobile ? 'mobile-shopboard-social-media-post-tag-products-button' : 'shopboard-social-media-post-tag-products-button'} onClick={onTagProductsClicked}>{t('SHOPBOARD_SOCIAL_MEDIA_POST_TAG_PRODUCTS_BUTTON_TEXT')}</div> :
                    <ShopboardSocialMediaPostProductsTag
                        taggedProducts={taggedProducts}
                        onTagProducts={onTagProductsClicked}
                    />
            }
        </div>
    )
}