import React from 'react'
import './MultilinkExperiencesTableHeaderView.css'
import { useTranslation } from 'react-i18next'
import { Box, TableCell, TableSortLabel, tableCellClasses } from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS } from 'constants/general.constants'

/**
 * Represents a header view for the orders table
 * @param {string} order - The way of ordering the column data, e.g., asc or desc
 * @param {string} orderBy - The column to order the data by
 * @param {string} headerBackgroundColor - The background color of the header view
 * @param {string} headerTextColor - The text color of the header view
 * @param {string} createSortHandler - A function for creating the sort function for the data ordering
 * @param {array} removedColumnIds - An array of column IDs that are required to remove from display
 */
export default function MultilinkExperiencesTableHeaderView({
    order,
    orderBy,
    headerBackgroundColor = '',
    headerTextColor = '',
    createSortHandler = () => { },
}) {
    const { t } = useTranslation()

    const headerContentMap = {
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.NAME]: <div className='multilink-experiences-table-header-view name'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_NAME')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TYPE]: <div className='multilink-experiences-table-header-view link-type'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_TYPE')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.DEVICE]: <div className='multilink-experiences-table-header-view'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_DEVICE')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TRAFFIC]: <div className='multilink-experiences-table-header-view'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_TRAFFIC')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CLICKS]: <div className='multilink-experiences-table-header-view'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_CLICKS')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.SALES]: <div className='multilink-experiences-table-header-view'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_SALES')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.REVENUE]: <div className='multilink-experiences-table-header-view'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_REVENUE')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CR]: <div className='multilink-experiences-table-header-view'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_CR')}</div>,
        [MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CART_AVERAGE]: <div className='multilink-experiences-table-header-view'>{t('MULTILINK_EXPERIENCES_TABLE_HEADER_CART_AVERAGE')}</div>
    }

    const headerStyle = {
        backgroundColor: headerBackgroundColor,
        color: headerTextColor
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: headerStyle,
    }))

    const MultilinkExperiencesTableHeaderCell = ({ id, label, align, tableCellView: TableCellView = StyledTableCell }) => <TableCellView
        key={id}
        align={align}
        padding={'none'}
        sortDirection={orderBy === id ? order : false}
    >
        <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : 'asc'}
            onClick={createSortHandler(id)}
        >
            {label}
            {orderBy === id ? (
                <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
            ) : null}
        </TableSortLabel>
    </TableCellView>

    return (
        <>
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.NAME} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.NAME]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TYPE} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TYPE]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.DEVICE} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.DEVICE]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TRAFFIC} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.TRAFFIC]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CLICKS} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CLICKS]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.SALES} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.SALES]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.REVENUE} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.REVENUE]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CR} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CR]
            } />
            <MultilinkExperiencesTableHeaderCell id={MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CART_AVERAGE} align={'left'} label={
                headerContentMap[MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS.CART_AVERAGE]
            } />
        </>
    )
}