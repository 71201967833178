import React from 'react'
import './PricingPlansPage.css'
import { useTranslation } from 'react-i18next'
import PricingPlan from 'components/PricingPlan/PricingPlan'
import { PRICING_PLAN_DATA } from 'constants/general.constants'
import { isMobile } from 'react-device-detect'

export default function PricingPlansPage() {
    const { t } = useTranslation()

    return (
        <div className='pricing-plans-page-container'>
            <div className="pricing-plans-page-content">
                {!isMobile && <div className="pricing-plans-page-title">{t('PRICING_PLANS_PAGE_TITLE')}</div>}
                <div className="pricing-plans-page-plans-container">
                    {/* <PricingPlan
                        name={PRICING_PLAN_DATA.FREE.name}
                        color={PRICING_PLAN_DATA.FREE.color}
                        price={PRICING_PLAN_DATA.FREE.price}
                        pricingPeriod={PRICING_PLAN_DATA.FREE.pricingPeriod}
                        features={PRICING_PLAN_DATA.FREE.features}
                        description={PRICING_PLAN_DATA.FREE.description}
                        ordinal={PRICING_PLAN_DATA.FREE.ordinal}
                    /> */}
                    <PricingPlan
                        name={PRICING_PLAN_DATA.STARTER.name}
                        color={PRICING_PLAN_DATA.STARTER.color}
                        price={PRICING_PLAN_DATA.STARTER.price}
                        pricingPeriod={PRICING_PLAN_DATA.STARTER.pricingPeriod}
                        features={PRICING_PLAN_DATA.STARTER.features}
                        description={PRICING_PLAN_DATA.STARTER.description}
                        ordinal={PRICING_PLAN_DATA.STARTER.ordinal}
                    />
                    <PricingPlan
                        name={PRICING_PLAN_DATA.BUSINESS.name}
                        color={PRICING_PLAN_DATA.BUSINESS.color}
                        price={PRICING_PLAN_DATA.BUSINESS.price}
                        pricingPeriod={PRICING_PLAN_DATA.BUSINESS.pricingPeriod}
                        features={PRICING_PLAN_DATA.BUSINESS.features}
                        description={PRICING_PLAN_DATA.BUSINESS.description}
                        ordinal={PRICING_PLAN_DATA.BUSINESS.ordinal}
                    />
                    <PricingPlan
                        name={PRICING_PLAN_DATA.PRO.name}
                        color={PRICING_PLAN_DATA.PRO.color}
                        price={PRICING_PLAN_DATA.PRO.price}
                        pricingPeriod={PRICING_PLAN_DATA.PRO.pricingPeriod}
                        features={PRICING_PLAN_DATA.PRO.features}
                        description={PRICING_PLAN_DATA.PRO.description}
                        ordinal={PRICING_PLAN_DATA.PRO.ordinal}
                    />
                    <PricingPlan
                        name={PRICING_PLAN_DATA.ENTERPRISE.name}
                        color={PRICING_PLAN_DATA.ENTERPRISE.color}
                        price={PRICING_PLAN_DATA.ENTERPRISE.price}
                        pricingPeriod={PRICING_PLAN_DATA.ENTERPRISE.pricingPeriod}
                        features={PRICING_PLAN_DATA.ENTERPRISE.features}
                        description={PRICING_PLAN_DATA.ENTERPRISE.description}
                        ordinal={PRICING_PLAN_DATA.ENTERPRISE.ordinal}
                        subtitle={PRICING_PLAN_DATA.ENTERPRISE.subtitle}
                    />
                </div>
            </div>
        </div>
    )
}