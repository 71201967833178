import { AUTH_TOKEN_KEY_LOCAL_STORAGE } from 'constants/storage.constants'
import React, { useContext, useState, useEffect } from 'react'
import { LocalSharedPreferences } from 'services/SharedPreferences'
import { GetLinksAPI, GetMultilinksAPI } from 'api/links/links'
import { AuthAPI, LoginAPI, RegisterAPI } from 'api/auth/auth'
import { environment } from "conf"
import {
    APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS,
    APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS,
    APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS,
    APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS,
    APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS,
    APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS,
    APPLICATION_READY_TIMEOUT_MILISECONDS,
    DEFAULT_CURRENCY_CODE,
    LINKS_PAGE_LINKS_FILTER_COUNT,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX,
    MULTILINKS_PAGE_MULTILINKS_FILTER_COUNT,
    NO_AUTH_PAGES,
    OPTION_TYPES,
    PAYDIN_DRAWER_DIRECTION,
    PAYDIN_DRAWER_TRANSITION_DURATION,
    PRICING_PLAN_DATA,
    SHOPBOARD_SOCIALS
} from 'constants/general.constants'
import { deleteQueryFromUrl, getQueryVariable } from 'services/SystemService'
import { ACCOUNT_VERIFICATION_PAGE_URL, AUTH_QUERY_PARAMETER_KEY, HTTPS_HOST_URL_PREFIX, PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import { influencerLoginAPI } from 'api/influencers/influencers'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import { useHistoryContext } from './History'
import { authenticateShopboardSocialMedia, connectShopboardSocialMedia, disconnectShopboardSocialMedia, getShopboardAnalytics, getShopboardPosts, getShopboardStatus, tagShopboardPostProducts, toggleShopboardPostVisibility } from 'api/shopboard/shopboard'
import { extractProductData, extractShopboardSocialMediaPost } from 'utils/dataUtils'
import { isMobile } from 'react-device-detect'
import FreeTrialStrip from 'components/FreeTrialStrip/FreeTrialStrip'
import FreeModeDialog from 'dialogs/FreeModeDialog/FreeModeDialog'
import { GetComplementaryProductsAPI, GetTopPerformingProductsAPI, GetTopProductsCombinationsAPI } from 'api/products/products'
import { getOptionsMap } from 'api/store/store'
import stc from 'string-to-color'

const userDetailsContext = React.createContext()
const generalDataContext = React.createContext()
const AuthContext = React.createContext()
const fetchLinksContext = React.createContext()
const fetchMultilinksContext = React.createContext()
const isReadyContext = React.createContext()
const isUserLoadedContext = React.createContext()

const generalDataInitialState = {
    pageMaxWidthPx: 0
}
const userDetailsInsitialState = {
    name: '',
    username: '',
    influencerUsername: '',
    influencerImageUrl: '',
    brand: {},
    businessName: '',
    businessLogo: '',
    businessCurrency: DEFAULT_CURRENCY_CODE,
    subscriptionPlan: 'FREE',
    freeTrialDaysLeft: 0,
    domain: '',
    shopUrl: '',
    isEditing: false,
    sidebarExpanded: true,
    recentActivitiesData: {
        links: [],
        shorts: [],
        influencers: {
            items: [],
            analytics: []
        }
    },
    linksData: {
        links: [
            {
                linkId: '',
                images: [],
                createdAt: 0,
                linkAnalytics: {
                    views: 0,
                    revenue: 0,
                    checkouts: 0,
                    currency: DEFAULT_CURRENCY_CODE
                }
            }
        ]
    },
    theme: {
        isInitial: true,
        link: {
            cartButtonImage: APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS.OPTION1,
            checkoutButtonShape: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION1,
            checkoutButtonBackgroundColor: '#000000',
            checkoutButtonBorderColor: '#000000',
            checkoutButtonFontColor: '#ffffff'
        },
        productImageStyle: {
            imageBorderRadius: APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS.find(option => option?.key === APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION1).key
        },
        salesAndDiscountStrip: {
            isSalesAndDiscountStripShown: false,
            salesAndDiscountStripBackgroundColor: '#000000',
            salesAndDiscountStripFontColor: '#ffffff',
            salesAndDiscountStripSentence1: '',
            salesAndDiscountStripSentence2: ''
        },
        inventorySettings: {
            isPreorderTextShown: false,
            preorderText: '',
        }
    },
    promocode: null,
    permissions: {
        commissionEnabled: false,
        clicksEnabled: false,
        revenueEnabled: false,
        salesEnabled: false,
        includeVAT: false,
        isActiveInfluencer: false
    },
    isAdmin: true,
    isVerified: false,
    isAuthenticated: false,
    utmParams: {
        UTMSource: '',
        UTMMedium: '',
        UTMCampaign: '',
        UTMTerm: '',
        UTMContent: '',
    },
    analytics: {
        linksLifetimeAnalytics: null,
        shortsLifetimeAnalytics: null
    },
    settings: {
        shippingAndReturnsUrl: '',
        facebookPixelId: '',
        googleAnalyticsId: '',
        autoSwapEnabled: false
    },
    shopboard: {
        synced: false,
        url: '',
        socialMedias: {
            [SHOPBOARD_SOCIALS.INSTAGRAM]: {
                connected: false,
                numberOfPosts: -1,
                posts: []
            },
            [SHOPBOARD_SOCIALS.TIKTOK]: {
                connected: false,
                numberOfPosts: -1,
                posts: []
            },
            [SHOPBOARD_SOCIALS.PINTEREST]: {
                connected: false,
                numberOfPosts: -1,
                posts: []
            }
        },
        analytics: {
            synced: false,
            currency: DEFAULT_CURRENCY_CODE,
            visits: 0,
            checkouts: 0,
            sales: 0,
            cr: 0,
            revenue: 0,
            data: [],
        }
    },
    trends: {
        topPerformingProducts: [],
        topProductsCombinations: {
            combinations: [],
            products: []
        },
        complementaryProducts: {}
    }
}

export function useUserDetailsContext() {
    return useContext(userDetailsContext)
}

export function useAuthContext() {
    return useContext(AuthContext)
}

export function useFetchLinksContext() {
    return useContext(fetchLinksContext)
}


export function useFetchMultilinksContext() {
    return useContext(fetchMultilinksContext)
}

export function useIsReadyContext() {
    return useContext(isReadyContext)
}

export function useIsUserLoadedContext() {
    return useContext(isUserLoadedContext)
}

export function useGeneralDataContext() {
    return useContext(generalDataContext)
}

/**
 * Providing an userDetails context to all children components.
 */
export default function UserProvider({ children, onUserLoaded, onReady }) {
    const history = useHistoryContext()
    const [userDetails, setUserDetails] = useState(userDetailsInsitialState)
    const [generalData, setGeneralData] = useState(generalDataInitialState)
    const [productsOptionsMap, setProductsOptionsMap] = useState(null)
    const [isReady, setIsReady] = useState(false)
    const [isUserLoaded, setIsUserLoaded] = useState(false)
    const [approvalMessageState, setApprovalMessageState] = useState({
        isOpen: false,
        message: ''
    })
    const [optionsDrawerState, setOptionsDrawerState] = useState({
        isOpen: false,
        onOpen: () => { },
        onClose: closeDrawer,
        hasPadding: true,
        children: <></>
    })
    const [finishedUpdatingObject, setFinishedUpdatingObject] = useState(false)
    const [finishedCreatingObject, setFinishedCreatingObject] = useState(false)
    const [objectToSave, setObjectToSave] = useState(false)

    const [freeTrialDialogState, setFreeTrialDialogState] = useState({
        isOpen: false,
        onOpen: () => { },
        onClose: closeFreeTrialDialog,
        onRightButtonClick: upgradeFreeTrialDialog,
        children: <></>,
        replace_text_to_max_breach: null,
        text: {}
    })

    useEffect(() => {
        setTimeout(() => {
            onReady(true)
            setIsReady(true)
        }, APPLICATION_READY_TIMEOUT_MILISECONDS)
        if (shouldVerify()) { // checking if the authentication token exists because the verify request always sends the authentication token, if not exists, verify fails.
            verify()
        } else {
            setIsUserLoaded(true)
            onUserLoaded(true)
        }
    }, [])

    function setRecentLinks(links) {
        setUserDetails(prev => ({
            ...prev,
            recentActivitiesData: {
                ...prev.recentActivitiesData,
                links
            }
        }))
    }

    function setRecentShorts(shorts) {
        setUserDetails(prev => ({
            ...prev,
            recentActivitiesData: {
                ...prev.recentActivitiesData,
                shorts
            }
        }))
    }

    function setRecentInfluencers(influencers) {
        setUserDetails(prev => ({
            ...prev,
            recentActivitiesData: {
                ...prev.recentActivitiesData,
                influencers: {
                    ...prev.recentActivitiesData.influencers,
                    items: influencers
                }
            }
        }))
    }

    function fetchProductsOptionsMap(onSuccess = () => {}, getCopy = false) {
        if (!productsOptionsMap) {
            getOptionsMap()
                .then(data => {
                    setProductsOptionsMap(data?.data)
                    if (getCopy) {
                        onSuccess(JSON.parse(JSON.stringify(data?.data)))
                    } else {
                        onSuccess(data?.data)
                    }
                })
                .catch(onSuccess({}))
        } else {
            if (getCopy) {
                onSuccess(JSON.parse(JSON.stringify(productsOptionsMap)))
            } else {
                onSuccess(productsOptionsMap)
            }
        }
    }

    function updateProductsOptionsMap(newProducsMap) {
        setProductsOptionsMap(JSON.parse(JSON.stringify(newProducsMap)))
    }

    function hasOptionsMap() {
        return Object.keys(productsOptionsMap).length > 0
    }

    function getOptionType(optionName) {
        if (!hasOptionsMap() || !productsOptionsMap[optionName]) {
            return OPTION_TYPES.TEXT
        }
        return productsOptionsMap[optionName]?.type
    }

    function isOptionColor(optionName) {
        return getOptionType(optionName) === OPTION_TYPES.COLOR
    }

    function getOptionValue(optionName, optionValueName, shouldReturnTextValue = false) {
        // if there's no options map json or the requested option doesn't exist or the requested option value doesn't exist
        if (!Object.keys(productsOptionsMap).length > 0 || !productsOptionsMap[optionName] || !productsOptionsMap[optionName]?.values[optionValueName]) {
            return null
        }

        const optionValueObject = productsOptionsMap[optionName]?.values[optionValueName]
        if (!shouldReturnTextValue && isOptionColor(optionName)) {
            if (!optionValueObject?.hex_value) {
                const noWhiteSpaceColor = optionValueObject?.text_value?.toLowerCase().replaceAll(/\s/g, '')
                return stc(noWhiteSpaceColor)
            }

            return optionValueObject?.hex_value
        } else {
            if (!optionValueObject?.text_value) {
                return optionValueName
            }

            return optionValueObject?.text_value
        }
    }

    function isFreeUser() {
        return userDetails?.subscriptionPlan === PRICING_PLAN_DATA.FREE.name.toUpperCase()
    }

    function isFreeUserAndTrialEnded() {
        return isFreeUser() && !(userDetails?.freeTrialDaysLeft > 0)
    }

    function isFreeUserAndTrialPastLastWeek() {
        return isFreeUser() && !(userDetails?.freeTrialDaysLeft > 7)
    }
    
    function isBusinessFeatureEnabled() {
        if (userDetails?.subscriptionPlan === PRICING_PLAN_DATA.PRO.name.toUpperCase() ||  userDetails?.subscriptionPlan === PRICING_PLAN_DATA.BUSINESS.name.toUpperCase()) {
            return true
        } 
        else if (userDetails?.subscriptionPlan === PRICING_PLAN_DATA.FREE.name.toUpperCase() && userDetails.freeTrialDaysLeft > 0) {
            return true
        }
        return false
    }

    function maxInfleuncersAllowed() {
        if (userDetails?.subscriptionPlan === PRICING_PLAN_DATA.FREE.name.toUpperCase() && userDetails?.freeTrialDaysLeft > 0) {
            return 3 // same as starter
        } else if (userDetails?.subscriptionPlan === PRICING_PLAN_DATA.STARTER.name.toUpperCase()) {
            return 3
        } else if (userDetails?.subscriptionPlan === PRICING_PLAN_DATA.BUSINESS.name.toUpperCase()) {
            return 15
        } else if (userDetails?.subscriptionPlan === PRICING_PLAN_DATA.PRO.name.toUpperCase()) {
            return Infinity
        }
        return 0
    }

    function shouldVerify() {
        let token = getQueryVariable(AUTH_QUERY_PARAMETER_KEY, null)
        if (token) {
            LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, token)
            deleteQueryFromUrl(AUTH_QUERY_PARAMETER_KEY)
        } else {
            token = LocalSharedPreferences.get_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, null)
        }
        return token && isNotInAuthPage()
    }

    function isNotInAuthPage() {
        return !NO_AUTH_PAGES.includes(window.location.pathname) && !window.location.pathname.startsWith(ACCOUNT_VERIFICATION_PAGE_URL)
    }

    function hasShopifyStore() {
        return userDetails.brand && Object.keys(userDetails.brand).length > 0
    }

    function getThemeValue(initialValue, value, valueOptions) {
        if (value) {
            if (typeof value === 'number') {
                return valueOptions[value].key ?? initialValue
            }

            if (typeof value === 'string') {
                return valueOptions.find(option => option?.key === value).key ?? initialValue
            }
        }

        return initialValue
    }

    function getThemeObject(userData) {
        return userData?.brand?.theme ? {
            isInitial: false,
            link: {
                cartButtonImage: getThemeValue(userDetailsInsitialState.theme.link.cartButtonImage, userData?.brand?.theme?.cart_button_icon, APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS),
                checkoutButtonShape: getThemeValue(userDetailsInsitialState.theme.link.checkoutButtonShape, userData?.brand?.theme?.checkout_button_shape, APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS),
                checkoutButtonBackgroundColor: userData?.brand?.theme?.checkout_button_background_color ?? userDetailsInsitialState.theme.link.checkoutButtonBackgroundColor,
                checkoutButtonBorderColor: userData?.brand?.theme?.checkout_button_border_color ?? userDetailsInsitialState.theme.link.checkoutButtonBorderColor,
                checkoutButtonFontColor: userData?.brand?.theme?.checkout_button_font_color ?? userDetailsInsitialState.theme.link.checkoutButtonFontColor
            },
            productImageStyle: {
                imageBorderRadius: getThemeValue(userDetailsInsitialState.theme.productImageStyle.imageBorderRadius, userData?.brand?.theme?.image_border_radius, APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS)
            },
            salesAndDiscountStrip: {
                isSalesAndDiscountStripShown: userData?.brand?.theme?.is_shipping_and_sales_strip_shown ?? userDetailsInsitialState.theme.salesAndDiscountStrip.isSalesAndDiscountStripShown,
                salesAndDiscountStripBackgroundColor: userData?.brand?.theme?.shipping_and_sales_strip_background_color ?? userDetailsInsitialState.theme.salesAndDiscountStrip.salesAndDiscountStripBackgroundColor,
                salesAndDiscountStripFontColor: userData?.brand?.theme?.shipping_and_sales_strip_font_color ?? userDetailsInsitialState.theme.salesAndDiscountStrip.salesAndDiscountStripFontColor,
                salesAndDiscountStripSentence1: userData?.brand?.theme?.shipping_and_sales_strip_sentences?.length > 0 ? userData?.brand?.theme?.shipping_and_sales_strip_sentences[0] : userDetailsInsitialState.theme.salesAndDiscountStrip.salesAndDiscountStripSentence1,
                salesAndDiscountStripSentence2: userData?.brand?.theme?.shipping_and_sales_strip_sentences?.length > 1 ? userData?.brand?.theme?.shipping_and_sales_strip_sentences[1] : userDetailsInsitialState.theme.salesAndDiscountStrip.salesAndDiscountStripSentence2
            },
            inventorySettings: {
                isPreorderTextShown: userData?.brand?.theme?.is_preorder_text_shown ?? userDetailsInsitialState.theme.inventorySettings.isPreorderTextShown,
                preorderText: userData?.brand?.theme?.preorder_text ?? userDetailsInsitialState.theme.inventorySettings.preorderText
            }
        } : userDetailsInsitialState.theme
    }

    function getPermissionsObject(userData) {
        return userData?.user?.permissions ? {
            commissionEnabled: userData?.user?.permissions?.analytics_commission_enabled ?? userDetailsInsitialState.permissions.commissionEnabled,
            clicksEnabled: userData?.user?.permissions?.analytics_clicks_enabled ?? userDetailsInsitialState.permissions.clicksEnabled,
            revenueEnabled: userData?.user?.permissions?.analytics_revenue_enabled ?? userDetailsInsitialState.permissions.revenueEnabled,
            salesEnabled: userData?.user?.permissions?.analytics_sales_enabled ?? userDetailsInsitialState.permissions.salesEnabled,
            crEnabled: userData?.user?.permissions?.analytics_cr_enabled ?? userDetailsInsitialState.permissions.crEnabled,
            includeVAT: userData?.user?.permissions?.analytics_include_vat ?? userDetailsInsitialState.permissions.includeVAT,
            aovEnabled: userData?.user?.permissions?.analytics_aov_enabled ?? userDetailsInsitialState.permissions.aovEnabled,
            isActiveInfluencer: userData?.user?.permissions?.allow_creating_and_editing_links ?? userDetailsInsitialState.permissions.isActiveInfluencer
        } : userDetailsInsitialState.permissions
    }

    function getUtmParamsObject(userData) {
        return userData?.brand?.utm_params ? {
            UTMSource: userData?.brand?.utm_params?.utm_source ?? '',
            UTMMedium: userData?.brand?.utm_params?.utm_medium ?? '',
            UTMCampaign: userData?.brand?.utm_params?.utm_campaign ?? '',
            UTMTerm: userData?.brand?.utm_params?.utm_term ?? '',
            UTMContent: userData?.brand?.utm_params?.utm_content ?? '',
        } : userDetailsInsitialState?.utmParams
    }

    function getSettingsObject(userData) {
        return {
            shippingAndReturnsUrl: userData?.brand?.shipping_and_return_url ?? userDetailsInsitialState.settings.shippingAndReturnsUrl,
            facebookPixelId: userData?.brand?.facebook_pixel_id ?? userDetailsInsitialState.settings.facebookPixelId,
            googleAnalyticsId: userData?.brand?.google_analytics_id ?? userDetailsInsitialState.settings.googleAnalyticsId,
            utmParams: getUtmParamsObject(userData),
            autoSwapEnabled: userData?.brand?.auto_swap_enabled ?? userDetailsInsitialState.settings.autoSwapEnabled
        }
    }

    function setUserProperties(userData) {
        setUserDetails(prev => ({
            ...prev,
            name: userData?.user?.name ?? "",
            username: userData?.user?.admin_username ?? "",
            influencerUsername: userData?.user?.username ?? "",
            influencerImageUrl: userData?.user?.profile_image_url ?? "",
            email: userData?.user?.email ?? "",
            brand: userData?.brand ?? {},
            businessId: userData?.brand?.business_id ?? "",
            businessName: userData?.brand?.business_name ?? "",
            businessLogo: userData?.brand?.logo_url ?? "",
            businessCurrency: userData?.brand?.currency ?? userDetailsInsitialState.businessCurrency,
            subscriptionPlan: userData?.brand?.subscription_plan ?? userDetailsInsitialState.subscriptionPlan,
            freeTrialDaysLeft: userData?.brand?.free_trial_days_left ?? userDetailsInsitialState.freeTrialDaysLeft,
            domain: userData?.brand?.domain ?? "",
            settings: getSettingsObject(userData),
            theme: getThemeObject(userData),
            isAdmin: userData?.is_admin ?? false,
            permissions: getPermissionsObject(userData),
            promocode: userData?.user?.promocode ?? '',
            isVerified: true,
            isAuthenticated: true
        }))
    }

    function setUnauthenticatedUserProperties() {
        setUserDetails({
            ...userDetailsInsitialState
        })
    }

    function fetchLinks(searchQuery, skip, shouldUpdateContext, linkType, onFinish = () => { }) {
        GetLinksAPI(userDetails?.businessId, searchQuery, LINKS_PAGE_LINKS_FILTER_COUNT, skip, linkType)
            .then((linksData) => {
                onFinish(linksData)
            }).catch((error) => {
                setUserDetails(prev => {
                    return {
                        ...prev,
                        linksData: {
                            links: [],
                            lastKey: null,
                        },
                    }
                })
                onFinish(null)
            })
    }


    function fetchMultilinks(searchQuery, skip, onFinish = () => { }) {
        GetMultilinksAPI(userDetails?.businessId, searchQuery, MULTILINKS_PAGE_MULTILINKS_FILTER_COUNT, skip)
            .then((multilinksData) => {
                onFinish(multilinksData)
            }).catch((error) => {
                onFinish(null)
            })
    }

    function register(username, password, email, onSuccess = () => { }, onFailure = () => { }) {
        RegisterAPI(username, password, email)
            .then(onSuccess)
            .catch(onFailure)
    }

    function login(username, influencerUsername, password, isInfluencerLogin, onLoggedIn) {
        if (isInfluencerLogin) {
            influencerLoginAPI(username, influencerUsername, password)
                .then((userData) => {
                    LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, userData.token)
                    setUserProperties(userData)
                    setIsUserLoaded(true)
                    onUserLoaded(true)
                    onLoggedIn(true)
                }).catch((error) => {
                    console.log(error)
                    setUnauthenticatedUserProperties()
                    setIsUserLoaded(true)
                    onUserLoaded(true)
                    onLoggedIn(false)
                })
        } else {
            LoginAPI(username, password)
                .then((userData) => {
                    LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, userData.token)
                    setUserProperties(userData)
                    setIsUserLoaded(true)
                    onUserLoaded(true)
                    onLoggedIn(true)
                }).catch((error) => {
                    console.log(error)
                    setUnauthenticatedUserProperties()
                    setIsUserLoaded(true)
                    onUserLoaded(true)
                    onLoggedIn(false)
                })
        }
    }

    function verify(onSuccess = () => { }, onFailure = () => { }) {
        AuthAPI()
            .then((userData) => {
                LocalSharedPreferences.set_key(AUTH_TOKEN_KEY_LOCAL_STORAGE, userData.token)
                setUserProperties(userData)
                setIsUserLoaded(true)
                onUserLoaded(true)
                onSuccess(userData)
            }).catch((error) => {
                setUnauthenticatedUserProperties()
                setIsUserLoaded(true)
                onUserLoaded(true)
                onFailure(error)
            })
    }

    function logout() {
        LocalSharedPreferences.remove_key(AUTH_TOKEN_KEY_LOCAL_STORAGE)
        setUserDetails({
            ...userDetailsInsitialState
        })
        setProductsOptionsMap(null)
    }

    function getFrontendUrl() {
        if (!isBusinessFeatureEnabled() || !userDetails.domain) {
            return environment.frontend_url
        }

        if (userDetails.domain.startsWith("http")) {
            return `${userDetails.domain}${environment.app_proxy_path}`
        } 
        return `${HTTPS_HOST_URL_PREFIX}${userDetails.domain}${environment.app_proxy_path}`
    }

    function getShopboardUrl() {
        return `${getFrontendUrl()}${userDetails?.username}`
    }

    function beginEditing() {
        setUserDetails(prev => ({
            ...prev,
            isEditing: true
        }))
    }

    function endEditing() {
        setUserDetails(prev => ({
            ...prev,
            isEditing: false
        }))
    }

    function isEditing() {
        return userDetails?.isEditing
    }

    function showApprovalMessage(message) {
        setApprovalMessageState({
            isOpen: true,
            message
        })
    }

    function onDrawerClosed() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: false
        }))
        setTimeout(() => {
            setOptionsDrawerState(prev => ({
                ...prev,
                children: <></>
            }))
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function closeDrawer() {
        history.goBack()
    }

    function openDrawer(hasPadding, content, height = null) {
        setOptionsDrawerState({
            isOpen: true,
            onOpen: () => { },
            onClose: onDrawerClosed,
            hasPadding,
            children: content,
            height
        })
    }

    function closeFreeTrialDialog() {
        setFreeTrialDialogState(prev => ({
            ...prev,
            isOpen: false,
            replace_text_to_max_breach: false
        }))
    }

    function upgradeFreeTrialDialog() {
        setTimeout(() => {
            history.push(PRICING_PLANS_PAGE_URL)
        }, 200)
    }

    function openFreeTrialDialog(replace_text_to_max_breach=false, title='', subtitle='') {
        setFreeTrialDialogState(prev => ({
            ...prev,
            isOpen: true,
            replace_text_to_max_breach,
            text: {
                title,
                subtitle,
            }

        }))
    }

    function setSidebarExpanded(expanded) {
        setUserDetails(prev => ({
            ...prev,
            sidebarExpanded: expanded
        }))
    }

    function shopboardConnect(social = null, onFailure = () => { }) {
        if (social !== null) {
            connectShopboardSocialMedia(social)
                .then(response => {
                    window.location.href = response?.redirect_url ?? ''
                })
                .catch(error => {
                    console.log(error)
                    onFailure(error)
                })
        }
    }

    function shopboardAuthenticate(social = null, code = '', onSuccess = () => { }, onFailure = () => { }) {
        if (social !== null) {
            authenticateShopboardSocialMedia(social, code)
                .then(response => {
                    onSuccess(response)
                })
                .catch(error => {
                    console.log(error)
                    onFailure(error)
                })
        }
    }

    function shopboardDisconnect(social = null, onSuccess = () => { }, onFailure = () => { }) {
        if (social !== null) {
            disconnectShopboardSocialMedia(social)
                .then(() => {
                    setUserDetails(prev => ({
                        ...prev,
                        shopboard: {
                            ...prev.shopboard,
                            socialMedias: {
                                ...prev.shopboard.socialMedias,
                                [social]: {
                                    ...prev.shopboard.socialMedias[social],
                                    connected: false
                                }
                            }
                        }
                    }))
                    onSuccess()
                })
                .catch(error => {
                    console.log(error)
                    onFailure(error)
                })
        }
    }

    function isShopboardConnected(social = null) {
        if (social === null) {
            return (
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.connected ||
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.connected ||
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.connected
            )
        }

        return userDetails?.shopboard?.socialMedias[social]?.connected
    }

    function isShopboardConnectedSyncing(social = null) {
        if (social === null) {
            return (
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.numberOfPosts === -1 ||
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.numberOfPosts === -1 ||
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.numberOfPosts === -1
            )
        }
        return userDetails?.shopboard?.socialMedias[social]?.numberOfPosts === -1
    }

    function isShopboardConnectedSynced(social = null) {
        if (social === null) {
            return (
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.numberOfPosts >= 0 ||
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.numberOfPosts >= 0 ||
                userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.numberOfPosts >= 0
            )
        }
        return userDetails?.shopboard?.socialMedias[social]?.numberOfPosts >= 0
    }

    function checkShopboardStatus(onSuccess = () => { }, onFailure = () => { }) {
        getShopboardStatus()
            .then(status => {
                setUserDetails(prev => ({
                    ...prev,
                    shopboard: {
                        ...prev.shopboard,
                        synced: true,
                        url: status?.shopboard_url ?? '',
                        socialMedias: {
                            [SHOPBOARD_SOCIALS.INSTAGRAM]: {
                                ...prev.shopboard.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM],
                                connected: status?.social_medias?.Instagram?.connected ?? false,
                                numberOfPosts: status?.social_medias?.Instagram?.number_of_posts ?? -1,
                                username: status?.social_medias?.Instagram?.username ?? ""
                            },
                            [SHOPBOARD_SOCIALS.TIKTOK]: {
                                ...prev.shopboard.socialMedias[SHOPBOARD_SOCIALS.TIKTOK],
                                connected: status?.social_medias?.Tiktok?.connected ?? false,
                                numberOfPosts: status?.social_medias?.Tiktok?.number_of_posts ?? -1,
                                username: status?.social_medias?.Tiktok?.username ?? ""
                            },
                            [SHOPBOARD_SOCIALS.PINTEREST]: {
                                ...prev.shopboard.socialMedias[SHOPBOARD_SOCIALS.PINTEREST],
                                connected: status?.social_medias?.Pinterest?.connected ?? false,
                                numberOfPosts: status?.social_medias?.Pinterest?.number_of_posts ?? -1,
                                username: status?.social_medias?.Pinterest?.username ?? ""
                            }
                        }
                    }
                }))
                onSuccess()
            })
            .catch(error => {
                console.log(error)
                onFailure()
            })
    }

    function fetchShopboardLifeTimeAnalytics() {
        getShopboardAnalytics()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    shopboard: {
                        ...prev.shopboard,
                        analytics: {
                            ...prev.shopboard.analytics,
                            synced: true,
                            visits: response?.analytics?.visits ?? '-',
                            sales: response?.analytics?.checkouts ?? '-',
                            checkouts: response?.analytics?.requested_checkouts ?? '-',
                            cr: (response?.analytics?.visits && response?.analytics?.visits > 0) ? ((response?.analytics?.checkouts ?? 0) / (response?.analytics?.visits)) * 100 : '-',
                            revenue: response?.analytics?.revenue ?? '-',
                            data: response?.analytics?.data ?? [],
                            currency: response?.currency ?? DEFAULT_CURRENCY_CODE
                        }
                    }
                }))
            })
            .catch(error => {
                console.log(error)
            })
    }

    function fetchShopboardPosts(social, count, skip, shouldOverridePostList = true, onSuccess = () => { }, onFailure = () => { }) {
        getShopboardPosts(social, count, skip)
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    shopboard: {
                        ...prev.shopboard,
                        socialMedias: {
                            ...prev.shopboard.socialMedias,
                            [social]: {
                                ...prev.shopboard.socialMedias[social],
                                posts: shouldOverridePostList ? response?.posts.map(post => extractShopboardSocialMediaPost(post)) : [...prev.shopboard.socialMedias[social]?.posts, ...response?.posts.map(post => extractShopboardSocialMediaPost(post))]
                            }
                        }
                    }
                }))
                onSuccess(response?.posts)
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }

    function hasSocialMediaPosts(social) {
        return userDetails?.shopboard?.socialMedias[social]?.posts.length > 0
    }

    function togglePostVisibility(social, postId, isVisible) {
        toggleShopboardPostVisibility(social, postId, isVisible)

        const updatedPosts = [...userDetails?.shopboard?.socialMedias[social]?.posts]
        const updatedPost = updatedPosts.find(post => post?.id === postId) ? { ...updatedPosts.find(post => post?.id === postId) } : {}
        const updatedPostIndex = updatedPosts.findIndex(post => post?.id === postId)

        updatedPost.isVisible = !updatedPost?.isVisible
        updatedPosts.splice(updatedPostIndex, 1, updatedPost)

        setUserDetails(prev => ({
            ...prev,
            shopboard: {
                ...prev.shopboard,
                socialMedias: {
                    ...prev.shopboard.socialMedias,
                    [social]: {
                        ...prev.shopboard.socialMedias[social],
                        posts: updatedPosts
                    }
                }
            }
        }))
    }

    function tagPostProducts(social, postId, products) {
        const productIds = products?.map(product => product?.id)
        const selectedProductsOptions = products?.reduce((acc, selectedProduct) => {
            if (Object.keys(selectedProduct?.selectedOptions).length > 0)
                return { ...acc, [selectedProduct.id]: selectedProduct?.selectedOptions}
            return acc
        }, {}) ?? {}
        tagShopboardPostProducts(social, postId, productIds, selectedProductsOptions)

        const updatedPosts = [...userDetails?.shopboard?.socialMedias[social]?.posts]
        const updatedPostIndex = updatedPosts.findIndex(post => post?.id === postId)
        
        if (updatedPostIndex !== -1) {
            const updatedPost = {...updatedPosts[updatedPostIndex]}
    
            updatedPost.products = products
            updatedPosts.splice(updatedPostIndex, 1, updatedPost)
    
            setUserDetails(prev => ({
                ...prev,
                shopboard: {
                    ...prev.shopboard,
                    socialMedias: {
                        ...prev.shopboard.socialMedias,
                        [social]: {
                            ...prev.shopboard.socialMedias[social],
                            posts: updatedPosts
                        }
                    }
                }
            }))
        }
    }

    function fetchTopPerformingProducts(onSuccess = () => { }, onFailure = () => { }) {
        GetTopPerformingProductsAPI()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    trends: {
                        ...prev?.trends,
                        topPerformingProducts: response ? response?.map(product => extractProductData(product)) : []
                    }
                }))
                onSuccess()
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }


    function fetchTopProductsCombinations(onSuccess = () => { }, onFailure = () => { }) {
        GetTopProductsCombinationsAPI()
            .then(response => {
                setUserDetails(prev => ({
                    ...prev,
                    trends: {
                        ...prev?.trends,
                        topProductsCombinations: {
                            combinations: response?.top_combinations ?? [],
                            products: response?.products ? response?.products?.map(product => extractProductData(product)) : []
                        }
                    }
                }))
                onSuccess()
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }


    function fetchComplementaryProducts(inventoryId, onSuccess = () => { }, onFailure = () => { }) {
        if (Object.keys(userDetails?.trends?.complementaryProducts).includes(inventoryId)) {
            onSuccess()
        } else {
            GetComplementaryProductsAPI(inventoryId)
                .then(response => {
                    const complementaryProducts = response?.map(product => {
                            return {
                                combinationScore: product?.combination_score ?? 0,
                                product: extractProductData(product)
                            }
                        })
                    setUserDetails(prev => ({
                        ...prev,
                        trends: {
                            ...prev?.trends,
                            complementaryProducts: {
                                ...prev?.trends?.complementaryProducts,
                                [inventoryId]: complementaryProducts
                            }
                        }
                    }))
                    onSuccess(complementaryProducts)
                })
                .catch(error => {
                    console.log(error)
                    onFailure(error)
                })
        }
    }

    return (
        <userDetailsContext.Provider value={{
            userDetails,
            setUserDetails,
            setRecentLinks,
            setRecentShorts,
            setRecentInfluencers,
            isFreeUser,
            isFreeUserAndTrialEnded,
            isFreeUserAndTrialPastLastWeek,
            isBusinessFeatureEnabled,
            maxInfleuncersAllowed,
            getFrontendUrl,
            getShopboardUrl,
            hasShopifyStore,
            beginEditing,
            endEditing,
            isEditing,
            setSidebarExpanded,
            shopboardConnect,
            shopboardAuthenticate,
            shopboardDisconnect,
            isShopboardConnected,
            checkShopboardStatus,
            isShopboardConnectedSyncing,
            isShopboardConnectedSynced,
            fetchShopboardLifeTimeAnalytics,
            fetchShopboardPosts,
            hasSocialMediaPosts,
            togglePostVisibility,
            tagPostProducts,
            fetchTopPerformingProducts,
            fetchTopProductsCombinations,
            fetchComplementaryProducts
        }}>
            <AuthContext.Provider value={{ setUserProperties, login, register, verify, logout }}>
                <fetchLinksContext.Provider value={{fetchLinks, fetchMultilinks}}>
                    <isReadyContext.Provider value={isReady}>
                        <isUserLoadedContext.Provider value={isUserLoaded}>
                            <generalDataContext.Provider value={{
                                generalData,
                                setGeneralData,
                                showApprovalMessage,
                                openDrawer,
                                closeDrawer,
                                finishedUpdatingObject,
                                finishedCreatingObject,
                                objectToSave,
                                setObjectToSave,
                                setFinishedCreatingObject,
                                setFinishedUpdatingObject,
                                openFreeTrialDialog,
                                closeFreeTrialDialog,
                                productsOptionsMap,
                                fetchProductsOptionsMap,
                                updateProductsOptionsMap,
                                getOptionValue,
                            }}>
                                {children}
                                <ApprovalMessage
                                    isMessageShown={approvalMessageState.isOpen}
                                    closeMessage={() => setApprovalMessageState(prev => ({ ...prev, isOpen: false }))}
                                    text={approvalMessageState.message}
                                />

                                <PaydinDrawer
                                    zIndex={3000}
                                    height={optionsDrawerState.height}
                                    direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                                    isOpen={optionsDrawerState.isOpen}
                                    onOpen={optionsDrawerState.onOpen}
                                    onClose={optionsDrawerState.onClose}
                                    hasPadding={optionsDrawerState.hasPadding}
                                    topRightBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                                    topLeftBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                                >
                                    {optionsDrawerState.children}
                                </PaydinDrawer>
                                {
                                    (!isMobile && isFreeUser() && (userDetails?.isAdmin || isFreeUserAndTrialEnded()) && userDetails.isAuthenticated) && <FreeTrialStrip daysLeft={userDetails.freeTrialDaysLeft}/>
                                }
                                {
                                    <FreeModeDialog 
                                        isDialogOpen={freeTrialDialogState.isOpen}
                                        handleDialogClose={freeTrialDialogState.onClose}
                                        replace_text_to_max_breach={freeTrialDialogState?.replace_text_to_max_breach ? freeTrialDialogState?.replace_text_to_max_breach : false}
                                        title={freeTrialDialogState?.replace_text_to_max_breach ? freeTrialDialogState?.text?.title : ''}
                                        subtitle={freeTrialDialogState?.replace_text_to_max_breach ? freeTrialDialogState.text?.subtitle : ''}
                                    />
                                }
                            </generalDataContext.Provider>
                        </isUserLoadedContext.Provider>
                    </isReadyContext.Provider>
                </fetchLinksContext.Provider>
            </AuthContext.Provider>
        </userDetailsContext.Provider>
    );
}
