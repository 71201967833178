import React, { useEffect, useRef, useState } from 'react'
import './EditLinkSection.css'
import { useTranslation } from 'react-i18next'
import ImageBox from 'components/ImageBox/ImageBox'
import AddProductsDialog from 'dialogs/AddProductsDialog/AddProductsDialog'
import { isMobile } from 'react-device-detect'
import {
    EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT,
    VIEW_MODES,
    PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS,
    PROMOCODE_INPUT_MAX_LENGTH,
    RESIZE_IMAGE_MAX_HEIGHT,
    RESIZE_IMAGE_MAX_WIDTH,
    SECTION_TYPES,
    ALERT_MESSAGE_TYPES,
    LINK_PRODUCT_SELECT_MAX_LIMIT
} from 'constants/general.constants'
import { useUserDetailsContext } from 'contexts/User'
import { PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import ImageCropDialog from 'dialogs/ImageCropDialog/ImageCropDialog'
import EditFieldSectionInput from 'components/EditFieldSectionInput/EditFieldSectionInput'
import { resizeImage } from 'services/imageUtils'
import AddInfluencerSelect from 'components/AddInfluencerSelect/AddInfluencerSelect'
import UTMForm from 'components/UTMForm/UTMForm'
import MultiSelectStatusButton from 'components/MultiSelectStatusButton/MultiSelectStatusButton'
import ItemsViewSection from 'components/ItemsViewSection/ItemsViewSection'
import PreviewLinkProductsListItem from 'components/PreviewLinkProductsListItem/PreviewLinkProductsListItem'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import AlertMessage from 'components/AlertMessage/AlertMessage'

/**
 * Represents the section in which the link data can be both displayed and edited.
 * @param {string} mode - The mode in which the user is in. Can be either one of VIEW_MODES.EDIT or VIEW_MODES.CREATE. 
 *                          It is useful due to the fact that this component can be either in edit link page and in create link page.
 * @param {string} image - The image url of the link
 * @param {function} setImage - A function that sets the user's selected image
 * @param {array} selectedProducts - The list of products selected by the user
 * @param {function} setSelectedProducts - A function that sets the list of products selected by the user
 * @param {string} description - The description of the current link
 * @param {function} setDescription - A function that sets the description of the current link
 * @param {string} promocode - A promocode for the current link
 * @param {function} setPromocode - A function that sets the promocode for the current link
 * @param {string} promocodeDescription - The description of the promocode for the current link
 * @param {function} setPromocodeDescription - A function that sets the description of the promocode for the current link
 * @param {string} shoppingUrl - The shopping URL of the current link
 * @param {function} setShoppingUrl - A function that sets the shopping URL of the current link
 * @param {boolean} linkPropertiesModified - Determins whether any change was made in the link details or not
 * @param {array} influencers - The array of all the influencers for the current user
 * @param {string} originalInfluencerUsername - The currently saved influencer's username
 * @param {string} selectedInfluencerUsername - The currently selected influencer's username
 * @param {function} setSelectedInfluencerUsername - A function for setting the currently selected influencer's username 
 */
export default function EditLinkSection({
    mode,
    image = '',
    setImage = () => { },
    selectedProducts = [],
    setSelectedProducts = () => { },
    selectedCollections = [],
    setSelectedCollections = () => { },
    description = '',
    setDescription = () => { },
    promocode = '',
    setPromocode = () => { },
    shouldAddUTMs = false,
    setShouldAddUTMs = () => { },
    utmParams = {},
    setUTMSource = () => { },
    setUTMMedium = () => { },
    setUTMCampaign = () => { },
    setUTMTerm = () => { },
    setUTMContent = () => { },
    linkPropertiesModified = false,
    influencers = [],
    originalInfluencerUsername = '',
    selectedInfluencerUsername = '',
    setSelectedInfluencerUsername = () => { },
    hasSavedInfluencer = false,
    setIsInfluencerChanged = () => { }
}) {
    const { t } = useTranslation()
    const { 
        userDetails,
        endEditing 
    } = useUserDetailsContext()
    const history = useHistoryContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [multiSelectDialogsState, setMultiSelectDialogsState] = useState({
        productsDialogShown: false,
        collectionsDialogShown: false
    })
    const [isImageCropDialogOpen, setIsImageCropDialogOpen] = useState(false)
    const [cropperTempImage, setCropperTempImage] = useState('')
    const [displayImage, setDisplayImage] = useState(image)

    const fileInputRef = useRef(null)

    useEffect(() => {
        setDisplayImage(image)
    }, [image])

    function openAddProductsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            productsDialogShown: true
        }))
    }

    function closeAddProductDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            productsDialogShown: false
        }))
    }

    function openAddCollectionsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: true
        }))
    }

    function closeAddCollectionsDialog() {
        setMultiSelectDialogsState(prev => ({
            ...prev,
            collectionsDialogShown: false
        }))
    }

    async function onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            const resizedImage = await resizeImage(event.target.files[0], RESIZE_IMAGE_MAX_WIDTH, RESIZE_IMAGE_MAX_HEIGHT)
            setCropperTempImage(URL.createObjectURL(resizedImage))
            setIsImageCropDialogOpen(true)
        }
    }

    function addMedia() {
        fileInputRef.current.value = null
        fileInputRef.current.click()
    }

    function showDiscardChangesDialog() {
        setDialogState({
            isOpen: true,
            handleDialogClose: closeDialog,
            title: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_TITLE'),
            message: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_MESSAGE'),
            leftButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('LINK_VIEW_DISCARD_LINK_DETAILS_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: () => {
                if (linkPropertiesModified) {
                    history.goBackStepsNoPerform(3)
                    endEditing()
                }
                setTimeout(goToPricingPlans, 200)
            }
        })
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function goToPricingPlans() {
        history.push(PRICING_PLANS_PAGE_URL)
    }

    function onApplyCrop(croppedImage, crop, zoom, aspectRatio) {
        setImage(croppedImage)
        closeImageCropDialog()
    }

    function closeImageCropDialog() {
        setIsImageCropDialogOpen(false)
    }

    function onSelectInfluencer(selectedInfluencerUsername) {
        const selectedInfluencer = influencers.find(influencer => influencer?.username === selectedInfluencerUsername)
        setPromocode(selectedInfluencer?.promocode)
        setSelectedInfluencerUsername(selectedInfluencerUsername)
        setIsInfluencerChanged(true)
    }

    function renderAddMediaFrame(className = '') {
        return <div className={`${isMobile ? 'mobile-edit-link-section-add-media-button' : 'edit-link-section-add-media-button'} ${className}`}>
            <div className="edit-link-section-add-media-button-inner-container">
                <div className={isMobile ? "mobile-edit-link-section-add-media-button-plus" : "edit-link-section-add-media-button-plus"}>+</div>
                <div className="edit-link-section-add-media-button-text">{t('EDIT_LINK_SECTION_ADD_MEDIA_BUTTON_TEXT')}</div>
            </div>
        </div>
    }

    function renderTitleHeader() {
        return <div className="edit-link-section-link-title-header">
            {
                userDetails?.businessLogo && <ImageBox
                    image={userDetails?.businessLogo}
                    className='edit-link-section-link-title-header-image'
                    showBorder={true}
                    roundImage={true}
                />
            }
            {
                userDetails?.businessName && <MultiLineText
                    text={userDetails?.businessName}
                    className='edit-link-section-link-title'
                    lines={1}
                />
            }
        </div>
    }

    return (
        <div className={isMobile ? 'mobile-edit-link-section-container' : 'edit-link-section-container'}>
            {
                isMobile ? <div className='mobile-edit-link-section-top-container'>
                    <div className="mobile-edit-link-section-image-section">
                        <div className="mobile-edit-link-section-image-section-data">
                            <div className={isMobile ? "mobile-edit-link-section-add-products-button-padding-container" : "edit-link-section-add-products-button-padding-container"}>
                                <MultiSelectStatusButton
                                    title={t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_TITLE')}
                                    status={t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_QUANTITY', { count: selectedProducts?.length })}
                                    onClick={openAddProductsDialog}
                                />
                            </div>
                            {
                                (selectedProducts && selectedProducts.length > 0) && <ItemsViewSection
                                    data={selectedProducts}
                                    viewItemComponent={PreviewLinkProductsListItem}
                                    type={SECTION_TYPES.STRIP}
                                    hasPadding={false}
                                    hasTitlePadding={false}
                                    itemsSliderClassName='mobile-edit-link-section-link-products-slider'
                                />
                            }
                        </div>
                    </div>
                    <div className="mobile-edit-link-section-link-title-container">
                        {renderTitleHeader()}
                        <EditFieldSectionInput
                            title={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_TITLE')}
                            placeholder={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_PLACEHOLDER')}
                            maxLength={EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT}
                            tooltipText={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TOOLTIP_TEXT')}
                            value={description}
                            setValue={setDescription}
                            isMultiline={true}
                            rows={2}
                            showTopSection={false}
                            id="title"
                        />
                    </div>
                </div> : <>
                    <div className={"edit-link-section-link-details"}>
                        {
                            displayImage && <div className={"edit-link-section-link-image-container"} onClick={addMedia}>
                                <ImageBox
                                    className="edit-link-section-link-image"
                                    image={displayImage}
                                />
                                {renderAddMediaFrame('has-image')}
                            </div>
                        }
                        <div className="edit-link-section-link-title-container">
                            {renderTitleHeader()}
                            <EditFieldSectionInput
                                title={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_TITLE')}
                                placeholder={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TEXTFIELD_PLACEHOLDER')}
                                maxLength={EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT}
                                tooltipText={t('EDIT_LINK_SECTION_LINK_DESCRIPTION_TOOLTIP_TEXT')}
                                value={description}
                                setValue={setDescription}
                                isMultiline={true}
                                rows={2}
                                showTopSection={false}
                                id="title"
                            />
                        </div>
                    </div>
                    <MultiSelectStatusButton
                        title={t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_TITLE')}
                        status={t('EDIT_LINK_SECTION_ADD_PRODUCTS_BUTTON_QUANTITY', { count: selectedProducts?.length })}
                        onClick={openAddProductsDialog}
                        className='edit-link-section-add-products-button'
                    />
                </>
            }
            {/* TODO Leave this code in comment until we'll find a solution to description for this feature */}
            {/* <MultiSelectStatusButton
                title={t('EDIT_LINK_SECTION_ADD_COLLECTIONS_BUTTON_TITLE', { count: selectedCollections?.length })}
                status={t('EDIT_LINK_SECTION_ADD_COLLECTIONS_BUTTON_QUANTITY', { count: selectedCollections?.length, collectionName: selectedCollections?.length > 0 && selectedCollections[0]?.title })}
                onClick={openAddCollectionsDialog}
            /> */}
            <div className="edit-link-section-link-fields">
                {
                    (userDetails?.isAdmin && influencers && influencers.length > 0) && <div className={isMobile ? "mobile-edit-link-section-add-products-button-container" : "edit-link-section-add-products-button-container"}>
                        <AddInfluencerSelect
                            isDisabled={originalInfluencerUsername !== '' || hasSavedInfluencer}
                            options={influencers}
                            optionState={selectedInfluencerUsername}
                            onOptionClick={onSelectInfluencer}
                            fullWidth={true}
                            isOptional={true}
                        />
                    </div>
                }
                <div className="edit-link-section-link-data-form-inputs">
                    <div className={isMobile ? "mobile-edit-link-section-promocode-input-container" : "edit-link-section-promocode-input-container"}>
                        <EditFieldSectionInput
                            title={t('EDIT_LINK_SECTION_PROMOCODE_INPUT_TITLE')}
                            placeholder={t('EDIT_LINK_SECTION_PROMOCODE_INPUT_PLACEHOLDER')}
                            maxLength={PROMOCODE_INPUT_MAX_LENGTH}
                            tooltipText={t('EDIT_LINK_SECTION_PROMOCODE_TOOLTIP_TEXT')}
                            value={promocode}
                            setValue={setPromocode}
                            id={t('EDIT_LINK_SECTION_PROMOCODE_INPUT_TITLE')}
                            isOptional={true}
                        />
                    </div>
                </div>
                <div className={isMobile ? "mobile-edit-link-section-utm-container" : "edit-link-section-utm-container"}>
                    {
                        userDetails?.isAdmin && <UTMForm
                            inlinePadding='20px'
                            shouldAddUTMs={shouldAddUTMs}
                            setShouldAddUTMs={setShouldAddUTMs}
                            UTMSource={utmParams.UTMSource}
                            setUTMSource={setUTMSource}
                            UTMMedium={utmParams.UTMMedium}
                            setUTMMedium={setUTMMedium}
                            UTMCampaign={utmParams.UTMCampaign}
                            setUTMCampaign={setUTMCampaign}
                            UTMTerm={utmParams.UTMTerm}
                            setUTMTerm={setUTMTerm}
                            UTMContent={utmParams.UTMContent}
                            setUTMContent={setUTMContent}
                        />
                    }
                </div>
            </div>
            <input ref={fileInputRef} type="file" accept="image/*" onChange={onImageChange} style={{ display: 'none' }} />
            <AddProductsDialog
                isDialogOpen={multiSelectDialogsState.productsDialogShown}
                handleDialogClose={closeAddProductDialog}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                selectMaxLimit={LINK_PRODUCT_SELECT_MAX_LIMIT}
                allowColorOptionSelect={true}
            />
            {/* TODO Leave this code in comment until we'll find a solution to description for this feature */}
            {/* {
                multiSelectDialogsState.collectionsDialogShown && <AddCollectionsDialog
                    isDialogOpen={multiSelectDialogsState.collectionsDialogShown}
                    handleDialogClose={closeAddCollectionsDialog}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={setSelectedCollections}
                    selectMaxLimit={EDIT_LINK_SECTION_ADD_COLLECTIONS_DIALOG_SELECTION_LIMIT}
                />
            } */}
            <ImageCropDialog
                isDialogOpen={isImageCropDialogOpen}
                handleDialogClose={closeImageCropDialog}
                image={cropperTempImage}
                aspectRatiosArray={PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS}
                onCropDone={onApplyCrop}
            />
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={false}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={false}
                onRightButtonClick={dialogState.rightButtonClickHandler}
            />
        </div>
    )
}