import React, { useState } from 'react'
import './DashboardTopBar.css'
import ImageBox from 'components/ImageBox/ImageBox'
import {
    CONTENT_TYPES,
    HOME_PAGE_DESKTOP_GET_INFLUENCERS_API_COUNT,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX,
    MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY,
    NOT_PRO_MAX_INFLUENCER_NUMBER,
    PAYDIN_DRAWER_DIRECTION,
    PAYDIN_DRAWER_TRANSITION_DURATION_WITH_BUFFER,
    SHOPEAKS_SMALL_LOGO_URL,
    SHOPEAKS_SMALL_LOGO_WHITE_URL,
    VIEW_MODES,
} from 'constants/general.constants'
import { BiLink } from 'react-icons/bi'
import { RiPaletteLine } from 'react-icons/ri'
import { TbDeviceDesktopAnalytics, TbSettings2 } from 'react-icons/tb'
import { FiUsers, FiUser } from 'react-icons/fi'
import { CiLogout, CiTimer } from 'react-icons/ci'
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { GrDocumentText } from 'react-icons/gr'
import { SlQuestion } from 'react-icons/sl'
import { AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useAuthContext, useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import {
    ACCOUNT_PAGE_URL,
    ANALYTICS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    LINKS_PAGE_URL,
    LOGIN_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    TERMS_PAGE_URL,
    SHOPIFY_HELP_PAGE_URL,
    SHORTS_PAGE_URL,
    SETTINGS_PAGE_URL,
    APPEARANCE_PAGE_URL,
    HOME_PAGE_URL,
    MAGIC_STORE_PAGE_URL,
    SHOPBOARD_PAGE_URL,
    PRICING_PLANS_PAGE_URL,
    MULTILINKS_PAGE_URL,
    TRENDS_PAGE_URL,
} from 'constants/routes.constants'
import { useHistoryContext } from 'contexts/History'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import LayoutTopbarRightSection from 'components/LayoutTopbarRightSection/LayoutTopbarRightSection'
import PaydinDrawerListContent from 'components/PaydinDrawerListContent/PaydinDrawerListContent'
import ShortsImage from 'embeddedImages/ShortsImage'
import MinimizedAddButton from 'components/MinimizedAddButton/MinimizedAddButton'
import HomePageImage from 'embeddedImages/HomePageImage'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import AddLInkFlow from 'components/AddLInkFlow/AddLInkFlow'
import MobileButtonStrip from 'components/MobileButtonStrip/MobileButtonStrip'
import MagicStoreImage from 'embeddedImages/MagicStoreImage'
import { GetInfluencers } from 'api/influencers/influencers'
import { extractInfluencerData } from 'utils/dataUtils'
import MultilinksImage from 'embeddedImages/MultilinksImage'
import ShopboardImage from 'embeddedImages/ShopboardImage'
import InfluencersImage from 'embeddedImages/InfluencersImage'

/**
 * Represents the dashboard top bar for all admin pages.
 */
export default function DashboardTopBar() {
    const { t } = useTranslation()
    const {
        userDetails,
        hasShopifyStore,
        setRecentInfluencers,
        isFreeUser,
        isFreeUserAndTrialEnded,
        isBusinessFeatureEnabled,
        maxInfleuncersAllowed,
    } = useUserDetailsContext()
    const {
        openFreeTrialDialog,
    } = useGeneralDataContext()
    const history = useHistoryContext()
    const { logout } = useAuthContext()

    const [isLogoutConfirmationDialogOpen, setIsLogoutConfirmationDialogOpen] = useState(false)
    const [optionsDrawerState, setOptionsDrawerState] = useState({
        isOpen: false,
        onOpen: openMoreOptionsDrawer,
        onClose: closeMoreOptionsDrawer,
        children: <></>
    })
    const [contentDialogState, setContentDialogState] = useState({
        isOpen: false,
        mode: VIEW_MODES.CREATE,
        onSaveSuccess: () => { }
    })
    const [addFlowTrigger, setAddFlowTrigger] = useState(false)

    function closeLinkContentDialog() {
        setContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showLoginConfirmationDialog() {
        optionsDrawerState.onClose()

        setTimeout(() => {
            setIsLogoutConfirmationDialogOpen(true)
        }, MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY)
    }

    function goToHome() {
        if (window.location.pathname !== HOME_PAGE_URL) {
            history.push(HOME_PAGE_URL)
        }
    }

    function goToLinks() {
        if (window.location.pathname !== LINKS_PAGE_URL) {
            history.push(LINKS_PAGE_URL)
        }
    }

    function goToShorts() {
        if (window.location.pathname !== SHORTS_PAGE_URL) {
            history.push(SHORTS_PAGE_URL)
        }
    }

    function goToMultilinks() {
        if (window.location.pathname !== MULTILINKS_PAGE_URL) {
            history.push(MULTILINKS_PAGE_URL)
        }
    }

    function goToAppearance() {
        closeDrawerAndGoToRoute(APPEARANCE_PAGE_URL)
    }

    function goToTrends() {
        if (isBusinessFeatureEnabled()) {
            closeDrawerAndGoToRoute(TRENDS_PAGE_URL)
        } else {
            history.goBack()
            setTimeout(() => { // wait for the drawer to close
                openFreeTrialDialog(true, t('TRENDS_PAGE_UPGRADE_TO_GET_ACCESS_TITLE'), t('TRENDS_PAGE_UPGRADE_TO_GET_ACCESS_SUBTITLE'))
            }, PAYDIN_DRAWER_TRANSITION_DURATION_WITH_BUFFER)
        }
    }

    function goToAnalytics(triggeredFromDrawer) {
        closeDrawerAndGoToRoute(ANALYTICS_PAGE_URL, triggeredFromDrawer)
    }

    function goToShopboard(triggeredFromDrawer) {
        closeDrawerAndGoToRoute(SHOPBOARD_PAGE_URL, triggeredFromDrawer)
    }

    function goToInfluencers() {
        if (window.location.pathname !== INFLUENCERS_PAGE_URL) {
            history.push(INFLUENCERS_PAGE_URL)
        }
    }

    function logOut() {
        logout()
        history.push(LOGIN_PAGE_URL)
    }

    function goToMyAccount() {
        closeDrawerAndGoToRoute(ACCOUNT_PAGE_URL)
    }

    function closeDialog() {
        setIsLogoutConfirmationDialogOpen(false)
    }

    function closeMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: false
        }))
    }
    
    function createNewLink() {
        setAddFlowTrigger(true)
    }
    
    function goToMagicStore() {
        history.push(MAGIC_STORE_PAGE_URL)
    }
    
    function goToPricingPlans() {
        if (!window.location.pathname.startsWith(PRICING_PLANS_PAGE_URL)) {
            history.push(PRICING_PLANS_PAGE_URL)
        }
    }
    
    function createNewInfluencer() {
        const maxInfleuncers = maxInfleuncersAllowed()
        if (maxInfleuncers == 0) {
            openFreeTrialDialog()
        } else {
            if (userDetails?.recentActivitiesData?.influencers?.items.length == 0) {
                GetInfluencers()
                    .then(data => {
                        const loadedInfluencers = data?.map(item => extractInfluencerData(item))?.filter(influencer => influencer?.isActive)
                        setRecentInfluencers(loadedInfluencers)
                        if (loadedInfluencers.length >= maxInfleuncers) {
                            if (userDetails?.recentActivitiesData?.influencers?.items.length >= maxInfleuncers) {
                                if (maxInfleuncers > 3) {
                                    openFreeTrialDialog(true, t('TOO_MANY_INFLUENCER_15_DIALOG_TITLE'), t('TOO_MANY_INFLUENCER_15_DIALOG_SUBTITLE'))
                                } else if (maxInfleuncers > 0) {
                                    openFreeTrialDialog(true, t('TOO_MANY_INFLUENCER_3_DIALOG_TITLE'), t('TOO_MANY_INFLUENCER_3_DIALOG_SUBTITLE'))
                                } else {
                                    openFreeTrialDialog()
                                }
                            }
                        } else {
                            setContentDialogState({
                                isOpen: true,
                                mode: VIEW_MODES.CREATE,
                                contentType: CONTENT_TYPES.INFLUENCER,
                                data: {},
                                onSaveSuccess: () => {
                                    setTimeout(() => { // gives some time to perform going back in history (inside the corresponding 'View' component) first and then navigating to the appropriate screen
                                        goToInfluencers()
                                    }, 200)
                                }
                            })
                        }
                    })
            } else if (userDetails?.recentActivitiesData?.influencers?.items.length >= maxInfleuncers) {
                if (maxInfleuncers > 3) {
                    openFreeTrialDialog(true, t('TOO_MANY_INFLUENCER_15_DIALOG_TITLE'), t('TOO_MANY_INFLUENCER_15_DIALOG_SUBTITLE'))
                } else if (maxInfleuncers > 0) {
                    openFreeTrialDialog(true, t('TOO_MANY_INFLUENCER_3_DIALOG_TITLE'), t('TOO_MANY_INFLUENCER_3_DIALOG_SUBTITLE'))
                } else {
                    openFreeTrialDialog()
                }
            } else {
                setContentDialogState({
                    isOpen: true,
                    mode: VIEW_MODES.CREATE,
                    contentType: CONTENT_TYPES.INFLUENCER,
                    data: {},
                    onSaveSuccess: () => {
                        setTimeout(() => { // gives some time to perform going back in history (inside the corresponding 'View' component) first and then navigating to the appropriate screen
                            goToInfluencers()
                        }, 200)
                    }
                })
            }
        }
    }

    function createNewShort() {
        setContentDialogState({
            isOpen: true,
            mode: VIEW_MODES.CREATE,
            contentType: CONTENT_TYPES.SHORT,
            data: {},
            onSaveSuccess: () => {
                setTimeout(() => { // gives some time to perform going back in history (inside the corresponding 'View' component) first and then navigating to the appropriate screen
                    goToShorts()
                }, 200)
            }
        })
    }


    function createNewMultilink() {
        setContentDialogState({
            isOpen: true,
            mode: VIEW_MODES.CREATE,
            contentType: CONTENT_TYPES.MULTILINK,
            data: {},
            onSaveSuccess: () => {
                setTimeout(() => { // gives some time to perform going back in history (inside the corresponding 'View' component) first and then navigating to the appropriate screen
                    goToMultilinks()
                }, 200)
            }
        })
    }

    function goToPrivacyPolicy() {
        closeDrawerAndGoToRoute(PRIVACY_POLICY_PAGE_URL)
    }

    function goToTermsAndConditions() {
        closeDrawerAndGoToRoute(TERMS_PAGE_URL)
    }

    function openShopifyHelpPage() {
        closeDrawerAndGoToRoute(SHOPIFY_HELP_PAGE_URL)
    }

    function openSettingsPage() {
        closeDrawerAndGoToRoute(SETTINGS_PAGE_URL)
    }

    function closeDrawerAndGoToRoute(url, triggeredFromDrawer = true) {
        if (isMobile) {
            if (triggeredFromDrawer) {
                history.goBack()
                if (window.location.pathname !== url) {
                    setTimeout(() => { // wait for the drawer to close
                        history.push(url)
                    }, PAYDIN_DRAWER_TRANSITION_DURATION_WITH_BUFFER)
                }
            } else {
                history.push(url)
            }
        } else {
            if (window.location.pathname !== url) {
                history.push(url)
            }
        }
    }

    function renderUserCircle() {
        if (userDetails?.isAdmin) {
            return <div onClick={openMoreOptionsDrawer} className='mobile-dashboard-topbar-user-circle-button mobile-dashboard-button'>
                <div onClick={openMoreOptionsDrawer} className='mobile-dashboard-topbar-user-circle-button-image mobile-dashboard-topbar-button-image'>
                    <div className={`mobile-dashboard-topbar-user-circle-button-username-initials-cirlce ${isFreeUser() ? "free" : ""}`}>
                        <div className="mobile-dashboard-topbar-user-circle-button-text">{userDetails?.username[0] ?? ''}</div>
                    </div>
                </div>
            </div>
        }

        return <ImageBox
            image={userDetails?.influencerImageUrl}
            roundImage={true}
            showBorder={true}
            className='mobile-dashboard-topbar-user-circle-image'
            imageClassName='mobile-dashboard-topbar-user-circle-image'
            onImageClickCallback={openMoreOptionsDrawer}
        />
    }

    function renderRightSection() {
        return (<>
            <div className='mobile-dashboard-topbar-right-section'>
                {
                    hasShopifyStore() && (userDetails?.isAdmin || userDetails?.permissions?.isActiveInfluencer) && <MinimizedAddButton
                        addImageClassName='mobile-dashboard-topbar-add-button mobile-dashboard-topbar-add-button'
                        menuClassName='mobile-dashboard-topbar-add-button-menu'
                        menuOptions={[
                            // {
                            //     text: t('MOBILE_DASHBOARD_TOPBAR_ADD_OPTIONS_MENU_MAGIC_STORE_OPTION_TEXT'),
                            //     image: <MagicStoreImage className="mobile-dashboard-topbar-add-options-menu-option-image" />,
                            //     onClick: goToMagicStore,
                            //     textClassName: 'mobile-dashboard-topbar-add-options-menu-magic-store-option'
                            // },
                            ...(!isFreeUserAndTrialEnded() ? [{
                                text: t('MOBILE_DASHBOARD_TOPBAR_ADD_OPTIONS_MENU_LINK_OPTION_TEXT'),
                                image: <BiLink className="mobile-dashboard-topbar-add-options-menu-option-image" />,
                                onClick: createNewLink
                            }] : []),
                            {
                                text: t('MOBILE_DASHBOARD_TOPBAR_ADD_OPTIONS_MENU_SHORT_OPTION_TEXT'),
                                image: <ShortsImage className="mobile-dashboard-topbar-add-options-menu-option-image" />,
                                onClick: createNewShort
                            },
                            ...((userDetails?.isAdmin && !isFreeUserAndTrialEnded()) ? [{
                                text: t('MOBILE_DASHBOARD_TOPBAR_ADD_OPTIONS_MENU_MULTILINK_OPTION_TEXT'),
                                image: <MultilinksImage className="mobile-dashboard-topbar-add-options-menu-option-image" />,
                                onClick: createNewMultilink
                            }] : []),
                            ...((userDetails?.isAdmin && !isFreeUserAndTrialEnded()) ? [{
                                text: t('MOBILE_DASHBOARD_TOPBAR_ADD_OPTIONS_MENU_INFLUENCER_OPTION_TEXT'),
                                image: <InfluencersImage className="mobile-dashboard-topbar-add-options-menu-option-image" />,
                                onClick: createNewInfluencer
                            }] : [])
                        ]}
                    />
                }
                {renderUserCircle()}
            </div>
        </>)
    }

    function openMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: true,
            onClose: closeMoreOptionsDrawer,
            children: <>
                <PaydinDrawerListContent
                    options={[
                        ...((userDetails?.isAdmin && hasShopifyStore()) ? [{
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_SHOPBOARD'),
                            image: <ShopboardImage className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToShopboard
                        }] : []),
                        ...((userDetails?.isAdmin && hasShopifyStore()) ? [{
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_MULTILINKS'),
                            image: <MultilinksImage className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToMultilinks
                        }] : []),
                        ...((userDetails?.isAdmin && hasShopifyStore()) ? [{
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_TRENDS'),
                            image: <RiPaletteLine className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToTrends
                        }] : []),
                        ...((userDetails?.isAdmin && hasShopifyStore()) ? [{
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_ANALYTICS'),
                            image: <TbDeviceDesktopAnalytics className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToAnalytics
                        }] : []),
                        ...(hasShopifyStore() ? [{
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_MY_ACCOUNT'),
                            image: <FiUser className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToMyAccount
                        }] : []),
                        ...((userDetails?.isAdmin && hasShopifyStore()) ? [{
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_APPEARANCE'),
                            image: <RiPaletteLine className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: goToAppearance
                        }] : []),
                        // {
                        //     text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_PRIVACY_POLICY'),
                        //     image: <MdOutlinePrivacyTip className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                        //     onClick: goToPrivacyPolicy
                        // },
                        // {
                        //     text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_TERMS_AND_CONDITIONS'),
                        //     image: <GrDocumentText className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                        //     onClick: goToTermsAndConditions
                        // },
                        // {
                        //     text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_HELP'),
                        //     image: <SlQuestion className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                        //     onClick: openShopifyHelpPage
                        // },
                        ...((userDetails?.isAdmin && hasShopifyStore()) ? [{
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_SETTINGS'),
                            image: <TbSettings2 className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: openSettingsPage
                        }] : []),
                        {
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_LOGOUT'),
                            className: 'mobile-dashboard-topbar-more-options-drawer-option-logout',
                            image: <CiLogout className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: showLoginConfirmationDialog
                        },
                        // {
                        //     text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CANCEL'),
                        //     image: <AiOutlineClose className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                        //     onClick: closeMoreOptionsDrawer
                        // }
                    ]}
                />
            </>
        }))
    }

    function hasAnalyticsPermission() {
        return userDetails?.permissions?.clicksEnabled ||
            userDetails?.permissions?.salesEnabled ||
            userDetails?.permissions?.revenueEnabled ||
            userDetails?.permissions?.commissionEnabled
    }

    return (
        <>
            <BrowserView>
                <div className='dashboard-topbar-container'>
                    <div className="dashboard-topbar-content">
                        <LayoutTopbarRightSection />
                    </div>
                    <div className="dashboard-topbar-bottom-line" />
                </div>
            </BrowserView>

            <MobileView>
                <div className='mobile-dashboard-topbar-container'>
                    <div className="mobile-dashboard-topbar-content">
                        <div className={`mobile-dashboard-topbar-paylink-title-container ${isFreeUser() && (userDetails?.isAdmin || isFreeUserAndTrialEnded()) ? "free" : ""}`}>
                            <div className="mobile-dashboard-topbar-paylink-logo-container">
                                <ImageBox
                                    image={isFreeUser() && (userDetails?.isAdmin || isFreeUserAndTrialEnded()) ? SHOPEAKS_SMALL_LOGO_WHITE_URL : SHOPEAKS_SMALL_LOGO_URL}
                                    className='mobile-dashboard-topbar-paylink-logo'
                                />
                                <div className="mobile-dashboard-topbar-store-name-container">
                                    { 
                                        (!isFreeUser() || (!userDetails?.isAdmin && !isFreeUserAndTrialEnded())) && <div className="mobile-dashboard-topbar-store-name">{hasShopifyStore() ? (userDetails?.isAdmin ? userDetails?.businessName : userDetails?.influencerUsername) : userDetails?.username}</div>
                                    }
                                    {
                                        isFreeUser() && (userDetails?.isAdmin || isFreeUserAndTrialEnded()) && <div className='mobile-dashboard-topbar-free-trial-section'>
                                            <div className="mobile-dashboard-topbar-store-free-trial-container" onClick={goToPricingPlans}>
                                                <div>{isFreeUserAndTrialEnded() ? t("FREE_TRIAL_STRIP_TRIAL_ENDED_TITLE") : t("FREE_TRIAL_STRIP_FREE_DAYS_LEFT_MOBILE", {daysLeft: userDetails.freeTrialDaysLeft})}</div>
                                                {
                                                    userDetails?.isAdmin && <div className='free-trial-upgrade-button'>{t("FREE_TRIAL_STRIP_UPGRADE_BUTTON_TEXT")}</div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {renderRightSection()}
                        </div>
                        <MobileButtonStrip
                            length={userDetails?.isAdmin || hasAnalyticsPermission() ? 4 : 3}
                            buttons={[
                                {
                                    text: t('DASHBOARD_TOPBAR_HOME_BUTTON_TEXT'),
                                    image: <HomePageImage className='mobile-dashboard-topbar-links-button-image mobile-dashboard-topbar-button-image' />,
                                    onClick: goToHome,
                                    isSelected: window.location.pathname === HOME_PAGE_URL,
                                    isDisabled: false
                                },
                                {
                                    text: t('DASHBOARD_TOPBAR_LINKS_BUTTON_TEXT'),
                                    image: <BiLink className='mobile-dashboard-topbar-links-button-image mobile-dashboard-topbar-button-image' />,
                                    onClick: goToLinks,
                                    isSelected: window.location.pathname === LINKS_PAGE_URL,
                                    isDisabled: !hasShopifyStore()
                                },
                                {
                                    text: t('DASHBOARD_TOPBAR_SHORTS_BUTTON_TEXT'),
                                    image: <ShortsImage className={`mobile-dashboard-topbar-shorts-button-image mobile-dashboard-topbar-button-image ${!hasShopifyStore() ? 'disabled' : ''}`} />,
                                    onClick: goToShorts,
                                    isSelected: window.location.pathname === SHORTS_PAGE_URL,
                                    isDisabled: !hasShopifyStore()
                                },
                                ...(userDetails?.isAdmin ? [{
                                    text: t('DASHBOARD_TOPBAR_INFLUENCERS_BUTTON_TEXT'),
                                    image: <FiUsers className='mobile-dashboard-topbar-influencers-button-image mobile-dashboard-topbar-button-image' />,
                                    onClick: goToInfluencers,
                                    isSelected: window.location.pathname === INFLUENCERS_PAGE_URL,
                                    isDisabled: !hasShopifyStore()
                                }] : (hasAnalyticsPermission() ? [{
                                    text: t('DASHBOARD_TOPBAR_ANALYTICS_BUTTON_TEXT'),
                                    image: <TbDeviceDesktopAnalytics className='mobile-dashboard-topbar-influencers-button-image mobile-dashboard-topbar-button-image' />,
                                    onClick: () => goToAnalytics(false),
                                    isSelected: window.location.pathname === ANALYTICS_PAGE_URL,
                                    isDisabled: false
                                }] : []))
                            ]}
                        />
                    </div>
                    <PaydinDrawer
                        direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                        isOpen={optionsDrawerState.isOpen}
                        onOpen={optionsDrawerState.onOpen}
                        onClose={optionsDrawerState.onClose}
                        hasPadding={false}
                        topRightBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                        topLeftBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                    >
                        {optionsDrawerState.children}
                    </PaydinDrawer>
                </div>
            </MobileView>
            <PaydinDialog
                title={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_TITLE')}
                message={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_MESSAGE')}
                isDialogOpen={isLogoutConfirmationDialogOpen}
                handleDialogClose={closeDialog}
                leftButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                rightButtonText={t('DASHBOARD_TOPBAR_LOGOUT_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                onRightButtonClick={logOut}
                closeOnRightClick={false}
                isLeftButtonWithLoader={false}
            />
            <ContentDialog
                isDialogOpen={contentDialogState.isOpen}
                handleDialogClose={closeLinkContentDialog}
                data={contentDialogState.data}
                mode={contentDialogState.mode}
                contentType={contentDialogState.contentType}
                onSaveSuccess={contentDialogState.onSaveSuccess}
            />
            <AddLInkFlow // FIX - check the current page you're on. If you're on the links page, re-fetch the links list.
                onAddLinkFlowDone={() => {
                    setTimeout(() => { // gives some time to perform going back in history (inside the corresponding 'View' component) first and then navigating to the appropriate screen
                        goToLinks()
                    }, 200)
                }}
                trigger={addFlowTrigger}
                setTrigger={setAddFlowTrigger}
            />
        </>
    )
}